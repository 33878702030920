import ButtonLinkSmall from "atoms/ButtonLinkSmall";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useUpdateStayLongerData } from "hooks/useUpdateStayLongerData";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import theme from "../pages/styles/Theme";
import { pSmall } from "../pages/styles/Typography";
import { LanguageContext } from "../provider/LanguageProvider";
import { getDefaultDateFormat } from "../utils/getDefaultDateFormat";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  MarginP,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  UnderlinedPCentered,
} from "./Contents/styles/Contents.styled";
import { Warning } from "./MessageBoxes";
dayjs.extend(LocalizedFormat);
dayjs.extend(IsSameOrAfter);

const LateCheckOutExplainer = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.25rem;
  ${pSmall};
  font-style: italic;
  text-align: center;
  color: ${theme.colors.white};
`;

function LateCheckOutCard({ daysBeforeArrival, lateCheckOutService }) {
  const { lang } = useContext(LanguageContext);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const { getReservation } = useAppContext();
  const reservation = getReservation(reservationId);
  const [hideCard, setHideCard] = useState(
    localStorage.getItem("hideLateCheckOutCard")
  );

  const [currentReservationId, setCurrentReservationId] = useState(null);
  useUpdateStayLongerData(currentReservationId);

  const handleRemindMeLater = () => {
    setHideCard(true);
    localStorage.setItem("hideLateCheckOutCard", "true");
  };

  if (hideCard && daysBeforeArrival >= 10) {
    return null;
  }

  return (
    <ThemedCard theme={"green"}>
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding={true}>
            <div>
              <ThemedCardTitle theme={"green"}>
                {dict("lateCheckOut.title", lang)}
              </ThemedCardTitle>
              <Message
                singleNightPrice={69}
                hasUpsellPotential={true}
                status={"NoRequestExisting"}
              />
            </div>
          </AlignIcon>
        </ThemedCardInfo>
        <div>
          <Form
            reservation={reservation}
            lateCheckOutService={lateCheckOutService}
          />

          {daysBeforeArrival >= 10 && (
            <UnderlinedPCentered onClick={handleRemindMeLater}>
              {dict("lateCheckOut.remindLater", lang)}
            </UnderlinedPCentered>
          )}
        </div>
      </CardText>
    </ThemedCard>
  );
}

const Message = ({ singleNightPrice }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <MarginP
        dangerouslySetInnerHTML={{
          __html: dict("lateCheckOut.textPossible", lang, [singleNightPrice]),
        }}
      />
    </>
  );
};

const Form = ({ reservation, lateCheckOutService }) => {
  const { lang } = useContext(LanguageContext);

  const checkOutDay = dayjs(reservation.departure).hour(18);

  return (
    <>
      <ButtonLinkSmall
        className="primary"
        caption={dict("lateCheckOut.toBookLateCheckOutExtra", lang)}
        to={
          "/extra?reservation=" +
          reservation.identifier +
          "&serviceId=" +
          lateCheckOutService.identifier +
          "&type=bookable&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
        }
      ></ButtonLinkSmall>
      <LateCheckOutExplainer>
        {dict("lateCheckOut.optionAfterExplainer", lang, [
          getDefaultDateFormat({
            date: checkOutDay,
            locale: lang,
            withTime: true,
          }),
        ])}
      </LateCheckOutExplainer>
    </>
  );
};

export default LateCheckOutCard;

import CollapsibleReservation from "atoms/CollapsibleReservation";
import { contents } from "data/ContentOutline";

function ReservationContentSections({
  reservation,
  unit,
  unitGroup,
  property,
  locationContent,
  unitGroupContent,
  cabinContent,
  cabin,
}) {
  return (
    <div>
      {contents.map((contentItem, index) => (
        <CollapsibleReservation
          key={index}
          reservation={reservation}
          unit={unit}
          unitGroup={unitGroup}
          property={property}
          locationContent={locationContent}
          cabinContent={cabinContent}
          unitGroupContent={unitGroupContent}
          cabin={cabin}
          contentItem={contentItem}
          {...contentItem}
        />
      ))}
    </div>
  );
}

export default ReservationContentSections;

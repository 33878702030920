import arrowRight from "assets/images/arrow-right.svg";
import car from "assets/images/car2.svg";
import dog from "assets/images/dog.svg";
import { ButtonAsDiv } from "./styles/ButtonLink.styled";

function ButtonDiv({
  className = "",
  caption,
  onClick = false,
  icon = arrowRight,
}) {
  const isGreen = className.includes("green");

  const buttonStyles = isGreen ? { justifyContent: "center" } : {};

  const iconStyles =
    icon === dog || car
      ? {
          marginTop: "1%",
          width: "24px",
          height: "24px",
          objectFit: "scale-down",
        }
      : { marginTop: "1%" };

  const props = {
    className,
    onClick,
  };

  // if (onClick) {
  //   props.onClick = onClick
  // }
  return (
    <ButtonAsDiv {...props} style={buttonStyles}>
      {caption}
      {!isGreen && <img src={icon} alt="icon" style={iconStyles} />}
    </ButtonAsDiv>
  );
}

export default ButtonDiv;

import * as Sentry from "@sentry/browser";

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_ENPOINT;
const METADATA_URL = process.env.REACT_APP_METADATA_API_ENPOINT + "locations/";
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

class PropertyService {
  getPropertyByPath(path) {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }
  getLocation(spotCode, locale = "en") {
    return axios
      .get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          locale: locale,
          populate: "location_url_on_raus_website",
          "filters[spot_code][$eq]": spotCode,
        },
      })
      .then((response) => {
        return response?.data?.data[0]?.attributes;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }
}

export default new PropertyService();

import check_box from "assets/images/check_box.svg";
import ButtonLink from "atoms/ButtonLink";
import FireWarning from "components/FireWarning";
import LateCheckOutCard from "components/LateCheckOutCard";
import StayLongerCard from "components/StayLongerCard";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { useAppContext, useAppState } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import infoIcon from "../../assets/images/info.svg";
import { Item } from "../../atoms/styles/Collapsible.styled";
import { useUpdateStayLongerData } from "../../hooks/useUpdateStayLongerData";
import { LanguageContext } from "../../provider/LanguageProvider";
import { DAYS_OF_THE_WEEK } from "../../utils/constants";
import { now } from "../../utils/today";
import dict from "../../utils/translations";
import {
  AlignIcon,
  BoxWrapper,
  ContentBox,
  DatesBox,
  DatesWrapper,
  H,
  Image,
  InfoBox,
  P,
} from "../Contents/styles/Contents.styled";
import { Warning } from "../MessageBoxes";
import { StyledLink } from "../styles/Header.styled";

const KeyCodeP = styled(P)`
  a {
    color: #006434;
  }
`;

function BasicFacts({ reservation, cabin }) {
  const { lang } = useContext(LanguageContext);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { getProperty, getLocation } = useAppContext();
  const [hideCard, setHideCard] = useState(
    localStorage.getItem("hideStayLongerCard") === "true"
  );

  useUpdateStayLongerData(reservationId);

  const property = getProperty(reservation);

  let spotCode = reservation.property.replace("/api/properties/", "");

  // handle LATE_CHE
  const lateCheckOutServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "LATE_CHE"
    ) || false;
  const lateCheckOutServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "LATE_CHE"
    ) || false;

  const lateCheckOutService =
    lateCheckOutServiceBookable || lateCheckOutServiceBooked;

  const newArrivalDate = dayjs(reservation.arrival);
  const arrivalTime = getArrivalTime({
    arrivalDate: newArrivalDate,
    lang,
    spotCode: cabin?.spot_code,
  });

  let day = newArrivalDate.date();
  let month = newArrivalDate.month() + 1;
  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;
  let arrivalWeekDay = newArrivalDate.locale(lang).format("dddd");

  let newDepartureDate = dayjs(reservation.departure);
  if (lateCheckOutServiceBooked) {
    newDepartureDate = dayjs(reservation.departure).hour(18);
  }

  const departureTime = getDepartureTime({
    departureDate: newDepartureDate,
    lang,
    spotCode: cabin?.spot_code,
  });

  let departureWeekDay = newDepartureDate.locale(lang).format("dddd");

  const dateArrival =
    arrivalWeekDay + ", " + day + "." + month + "." + newArrivalDate.year();

  day = newDepartureDate.date();
  month = newDepartureDate.month();
  month = month + 1;
  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;

  const dateDeparture =
    departureWeekDay + ", " + day + "." + month + "." + newDepartureDate.year();

  const daysBeforeArrival = getDaysBeforeArrival(reservation.arrival);
  const stayLongerStatus = reservation?.stayLonger?.stayLongerStatus;
  const hasUpsellPotentialAfter = stayLongerStatus?.possibleAfter === "Ja";
  /*  const hasUpsellPotentialBefore =
    daysBeforeArrival > 0 && stayLongerStatus?.possibleBefore === "Ja";
  const hasUpsellPotential =
    hasUpsellPotentialAfter || hasUpsellPotentialBefore;*/

  // console.log("IN BASICFACTS -------------");
  // console.log(" > reservation?.stayLonger", reservation?.stayLonger);
  // console.log(" > hasUpsellPotentialAfter", hasUpsellPotentialAfter);
  // console.log(" > hasUpsellPotentialBefore", hasUpsellPotentialBefore);
  // console.log(" > hasUpsellPotential", hasUpsellPotential);
  // console.log(" > stayLongerStatus", stayLongerStatus);
  // console.log(" > lang", lang);
  // console.log(" > lateCheckOutServiceBookable", lateCheckOutServiceBookable);
  // console.log(" > lateCheckOutServiceBooked", lateCheckOutServiceBooked);
  // console.log(" > daysBeforeArrival", daysBeforeArrival);

  useEffect(() => {
    if (
      daysBeforeArrival <= 7 &&
      localStorage.getItem("hideStayLongerCard") === "true"
    ) {
      setHideCard(false);
    } else {
      setHideCard(false);
    }
  }, [daysBeforeArrival]);

  const { hasCheckedIn } = useAppState();

  const getKeyCodeLabel = () => {
    // Even if the user hasn't checked-in yet, latest 2 days before arrival the code should be accessible
    // We want to prevent that people can't open the cabin door because they haven't checked-in yet
    if (!hasCheckedIn && daysBeforeArrival > 2)
      return dict("contents.tbaNotCheckedIn", lang);

    if (daysBeforeArrival > 2) return dict("contents.tba", lang);
    const hasAnyKeyCode = reservation?.keyCode || cabin?.nuki_fallback_code;
    if (!hasAnyKeyCode) return dict("contents.codeError", lang);
    return reservation?.keyCode || cabin?.nuki_fallback_code;
  };

  // handle StayLongerCard visibility
  // const singleNightPrice =
  //   reservation?.stayLonger?.singleNightPrice?.priceInEuro;
  // const stayLongerStatus = reservation?.stayLonger?.stayLongerStatus;
  // const missingData = !reservation || !stayLongerStatus || !singleNightPrice;
  // const hasUpsellPotential = !!stayLongerStatus?.possibleAfter;
  // const arrivalIsFarInTheFuture = daysBeforeArrival > 7;
  // const offerUpsell =
  //   !missingData && (!arrivalIsFarInTheFuture || upSellOnSunday);
  // console.log("singleNightPrice", singleNightPrice);
  // console.log("stayLongerStatus", stayLongerStatus);
  // console.log("missingData", missingData);
  // console.log("hasUpsellPotential", hasUpsellPotential);
  // console.log("arrivalIsFarInTheFuture", arrivalIsFarInTheFuture);
  // console.log("hasUpsellPotential", hasUpsellPotential);
  // console.log("upSellOnSunday", upSellOnSunday);
  // console.log("offerUpsell", offerUpsell);

  // handle additional codes for booked extras
  const AD_BIK_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BIK_LS"
    ) || false;
  const AD_EBIK_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_EBIK_LS"
    ) || false;
  const AD_BIK_ZW_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BIK_ZW"
    ) || false;
  const AD_BB_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BB_LS"
    ) || false;

  return (
    <ContentBox>
      <Item>
        <InfoBox>
          {cabin?.overview_image?.image_mobile?.data?.attributes?.formats
            ?.medium?.url ? (
            <Image
              src={
                cabin.overview_image.image_mobile.data.attributes.formats.medium
                  .url
              }
              alt="Cabin"
            />
          ) : (
            <Image src="/images/haus.svg" alt="Cabin" />
          )}
        </InfoBox>
        <BoxWrapper>
          <DatesWrapper>
            <DatesBox>
              <H>
                {!cabin
                  ? dict("contents.locationTitle", lang)
                  : dict("contents.cabinTitle", lang)}
              </H>
              <P>
                {!cabin || !cabin?.name || cabin.name === property.name ? (
                  <>{property.name}</>
                ) : (
                  <>{`${property.name}: ${cabin.name}`}</>
                )}
              </P>
            </DatesBox>
            <DatesBox>
              <H>
                {cabin.name === "Schlossgarten"
                  ? dict("contents.codeBox", lang)
                  : spotCode === "SPOT29"
                  ? dict("contents.codeLenzen", lang)
                  : dict("contents.code", lang)}
              </H>
              <KeyCodeP
                dangerouslySetInnerHTML={{ __html: getKeyCodeLabel() }}
              ></KeyCodeP>
            </DatesBox>
          </DatesWrapper>
        </BoxWrapper>

        {daysBeforeArrival <= 1 || hasCheckedIn ? (
          <BoxWrapper>
            <DatesWrapper>
              <DatesBox>
                <H>{dict("contents.codeCart", lang)}</H>
                <P>1535</P>
              </DatesBox>
              {AD_BIK_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBike", lang)}</H>
                  <P>{AD_BIK_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_EBIK_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeEBike", lang)}</H>
                  <P>{AD_EBIK_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_BIK_ZW_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBike", lang)}</H>
                  <P>{AD_BIK_ZW_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_BB_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBBQ", lang)}</H>
                  <P>{AD_BB_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
            </DatesWrapper>
          </BoxWrapper>
        ) : null}

        <BoxWrapper>
          <DatesWrapper>
            <DatesBox>
              <H>{dict("contents.arrival", lang)}</H>
              <P>
                {dateArrival} {dict("contents.arrivalFrom", lang)} {arrivalTime}{" "}
                {dict("contents.arrivalClock", lang)}
              </P>
            </DatesBox>
            <DatesBox>
              <H>{dict("contents.departure", lang)}</H>
              <P>
                {dateDeparture} {dict("contents.departureUntil", lang)}{" "}
                {departureTime} {dict("contents.departureClock", lang)}
              </P>
            </DatesBox>
          </DatesWrapper>
        </BoxWrapper>

        <BoxWrapper>
          <DatesWrapper>
            <DatesBox>
              <H>{dict("contents.confirmation", lang)}</H>
              <P>#{reservationId}</P>
            </DatesBox>
            <DatesBox>
              <AlignIcon>
                <H>{dict("pages.rate", lang)} </H>
                <StyledLink
                  to={"/booking/details?reservation=" + reservationId}
                >
                  <AlignIcon>
                    <img src={infoIcon} alt="health" />
                  </AlignIcon>
                </StyledLink>
              </AlignIcon>
              <P>{reservation.ratePlan.name}</P>
            </DatesBox>
          </DatesWrapper>
          <InfoBox></InfoBox>
          {!hasCheckedIn && (
            <InfoBox>
              <ButtonLink
                caption={dict("checkIn.finishCheckin", lang)}
                to={"/check-in?reservation=" + reservationId}
                icon={check_box}
              />
            </InfoBox>
          )}
        </BoxWrapper>

        <MaybeShowLateCheckout
          hasUpsellPotentialAfter={hasUpsellPotentialAfter}
          lateCheckOutServiceBooked={lateCheckOutServiceBooked}
          stayLongerStatus={stayLongerStatus?.status}
          lateCheckOutServiceBookable={lateCheckOutServiceBookable}
          lateCheckOutService={lateCheckOutService}
          lang={lang}
          reservation={reservation}
          params={params}
        />

        <MaybeShowStayLongerCard
          lateCheckOutServiceBooked={lateCheckOutServiceBooked}
          hasUpsellPotentialAfter={hasUpsellPotentialAfter}
          daysBeforeArrival={daysBeforeArrival}
          lang={lang}
          reservation={reservation}
          params={params}
        />

        {cabin.wbs_value >= 3 ? <FireWarning /> : null}
      </Item>
    </ContentBox>
  );
}

const MaybeShowStayLongerCard = ({
  hasUpsellPotentialAfter,
  lateCheckOutServiceBooked,
  daysBeforeArrival,
  lang,
  params,
  reservation,
}) => {
  if (!hasUpsellPotentialAfter || !!lateCheckOutServiceBooked) return;

  const departureDate = dayjs(reservation?.departure);
  const isDepartureOnSunday = departureDate.day() === DAYS_OF_THE_WEEK.SUNDAY;
  const isOneDayBeforeDeparture = departureDate
    .subtract(1, "day")
    .isSame(now(), "day");

  /** Throughout the week we only want to show upsell short before departure, to not "destroy" any slots that are still likely booked
   * The sunday upsell is always possible */
  if (!isDepartureOnSunday && !isOneDayBeforeDeparture) {
    return null;
  }

  const isTooLateToUpsell = now().isSameOrAfter(departureDate.startOf("day"));

  if (isTooLateToUpsell) {
    if (params?.intent === "extend") {
      return (
        <div>
          <Warning>{dict("stayLonger.notAvailableAnymore", lang)}</Warning>
        </div>
      );
    }
    return null;
  }

  return <StayLongerCard daysBeforeArrival={daysBeforeArrival} />;
};

const MaybeShowLateCheckout = ({
  hasUpsellPotentialAfter,
  stayLongerStatus,
  lateCheckOutServiceBookable,
  lateCheckOutServiceBooked,
  lateCheckOutService,
  lang,
  reservation,
  params,
}) => {
  if (
    !hasUpsellPotentialAfter ||
    stayLongerStatus !== "NoRequestExisting" ||
    !lateCheckOutServiceBookable
  ) {
    return null;
  }

  const departureDate = dayjs(reservation?.departure);
  const isDepartureOnSunday = departureDate.day() === DAYS_OF_THE_WEEK.SUNDAY;
  const isOneDayBeforeDeparture = departureDate
    .subtract(1, "day")
    .isSame(now(), "day");

  /** Throughout the week we only want to show upsell short before departure, to not "destroy" any slots that are still likely booked
   * The sunday upsell is always possible */
  if (!isDepartureOnSunday && !isOneDayBeforeDeparture) {
    return null;
  }

  const isTooLateToUpsell = now().isSameOrAfter(departureDate.startOf("day"));

  if (isTooLateToUpsell) {
    if (params?.intent === "extend") {
      return (
        <div>
          <Warning>{dict("lateCheckOut.notAvailableAnymore", lang)}</Warning>
        </div>
      );
    }
    return null;
  }

  if (lateCheckOutServiceBooked) {
    return <InfoBox>{dict("lateCheckOut.booked", lang)}</InfoBox>;
  }

  return (
    <LateCheckOutCard
      // the short daysBeforeArrival ensures that the card is shown also when the arrival is far in the future
      daysBeforeArrival={7}
      lateCheckOutService={lateCheckOutService}
    />
  );
};

const getDaysBeforeArrival = (arrivalDate) => {
  const arrival = dayjs(arrivalDate);
  return arrival.diff(now(), "day");
};

const getArrivalTime = ({ lang, arrivalDate, spotCode }) => {
  // for now we return a static departure time for fichtenwald because has an incorrect departure time in apaleo
  // TODO-LUCCA: Change back once fixed in apaleo
  if (spotCode === "SPOT_2") return "15:00";

  if (lang === "de") {
    return arrivalDate.format("HH:mm");
  }

  return arrivalDate.format("h:mm a");
};

const getDepartureTime = ({ lang, departureDate, spotCode }) => {
  // for now we return a static departure time for fichtenwald because has an incorrect departure time in apaleo
  // TODO-LUCCA: Change back once fixed in apaleo
  if (spotCode === "SPOT45") return "11:00";

  if (lang === "de") {
    return departureDate.format("HH:mm");
  }

  return departureDate.format("h:mm a");
};

export default BasicFacts;

import { useContext } from "react";
import { LoginBox, LoginTitle, LoginShortText } from "./styles/Login.styled";
import dict from "utils/translations";
import { LanguageContext } from "provider/LanguageProvider";
import { useAppContext } from "provider/AppProvider";

function ShortWelcome() {
  const { lang } = useContext(LanguageContext);
  const {
    state: { user },
  } = useAppContext();

  return (
    <LoginBox>
      <LoginTitle>
        {dict("pages.shortWelcomeTitle", lang)} {user.firstName},{" "}
      </LoginTitle>
      <LoginShortText>{dict("pages.shortWelcomeText", lang)}</LoginShortText>
    </LoginBox>
  );
}

export default ShortWelcome;

import car from "assets/images/car2.svg";
import play from "assets/images/play--outline.svg";
import { ExtraButton } from "./styles/ButtonLink.styled";

function ButtonLinkExtra({ caption, href, icon = play }) {
  const iconStyles =
    !!icon && icon === car
      ? {
          marginTop: "1%",
          width: "24px",
          height: "24px",
          objectFit: "scale-down",
        }
      : { marginTop: "1%" };
  return (
    <ExtraButton href={href} target="_blank">
      {caption}
      {!!icon && <img src={icon} alt="icon" style={iconStyles} />}
    </ExtraButton>
  );
}

export default ButtonLinkExtra;

import { InfoBox } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";
import { H2, H5, StickyWrapper } from "components/styles/Header.styled";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import { useParams } from "react-router-dom";

function CabinManualTopic() {
  const {
    state: { cabinManual: cabinManuals },
  } = useAppContext();
  const slugify = require("slugify");

  // this allows us to get the `topicSlug` from the URL: `/:reservationId/manual/:topicSlug`
  const params = useParams();

  // get all cabin-manual contents
  const cabinManual = cabinManuals?.[0];

  // add a slug for navigation
  cabinManual.topics.map(
    (topic) => (topic.slug = slugify(topic.title, { lower: true }))
  );

  // find the one topic
  const topic = cabinManual.topics.filter(
    (topic) => topic.slug === params.topicSlug
  )[0];

  return (
    <div>
      <StickyWrapper>
        <HeaderSubpage />
        {contents[6].id === "cabinManual" ? (
          <H5> {contents[6].title} </H5>
        ) : null}
        <H2> {topic.title} </H2>
      </StickyWrapper>
      <InfoBox>
        {topic.copy_top[0] ? (
          <div
            dangerouslySetInnerHTML={{ __html: topic.copy_top[0].text }}
          ></div>
        ) : (
          ""
        )}
        {/* todo images */}
        {topic.copy_bottom[0] ? (
          <div
            dangerouslySetInnerHTML={{ __html: topic.copy_bottom[0].text }}
          ></div>
        ) : (
          ""
        )}
      </InfoBox>
    </div>
  );
}

export default CabinManualTopic;

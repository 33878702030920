import { setReservation } from "hooks/useAppReducer";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import stayLongerService from "../services/stayLonger.service";

export const useUpdateStayLongerData = (reservationId) => {
  const { dispatch, getReservation } = useAppContext();

  const [stayLongerError, setStayLongerError] = useState(null);

  const [stayLongerData, setStayLongerData] = useState(null);
  const [isStaylongerFetching, setIsStaylongerFetching] = useState(false);
  const [isStayLongerDone, setIsStayLongerDone] = useState(false);

  const getStayLongerData = async (reservationId) => {
    if (!reservationId) {
      return;
    }

    let reservation = getReservation(reservationId);
    if (!reservation) {
      return;
    }

    setIsStaylongerFetching(true);
    try {
      let stayLongerStatus = await stayLongerService.getStatus(reservationId);
      // console.log("getStayLongerData stayLongerStatus", stayLongerStatus);
      const price = await stayLongerService.getPrice(reservationId);

      reservation["stayLonger"] = {
        stayLongerStatus,
        singleNightPrice: price,
      };
      dispatch(setReservation(reservation, reservationId));
      setStayLongerData(stayLongerStatus);
      setIsStayLongerDone(true);
    } catch (error) {
      setStayLongerError(error);
      setIsStayLongerDone(false);
    }
    setIsStaylongerFetching(false);
  };

  useEffect(() => {
    if (reservationId && !isStaylongerFetching) {
      getStayLongerData(reservationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  return {
    stayLongerData,
    isStayLongerDone,
    isStaylongerFetching,
    stayLongerError,
  };
};

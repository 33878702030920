import * as Sentry from "@sentry/browser";

import axios from "axios";

class stayLongerService {
  getStatus(reservationId) {
    return axios
      .get(
        `https://www.raus.life/api/companion/upsell-status-for-reservation?reservationId=${reservationId}`
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }

  getPrice(reservationId) {
    return {
      priceInEuro: 95,
    };
    // return axios
    //   .get(
    //     `https://hook.eu1.make.com/x4ybjnqghoahbcperybq7j04yxst4m8g?reservationId=${reservationId}`
    //   )
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch(function (error) {
    //     Sentry.captureException(error);
    //   });
  }

  requestToStayLonger(reservationId) {
    return axios
      .get(
        `https://www.raus.life/api/companion/create-upsell-request-for-reservation?reservationId=${reservationId}`
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }
}

export default new stayLongerService();

import styled, { css } from "styled-components";
import { p } from "../../pages/styles/Typography";

export const Button = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 13px 12px;
  width: 100%;

  ${p}
  &.primary {
    color: #ffffff;
    background: #23212c;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
  }

  &.yellow {
    color: #b318a4;
    background: #fcbd1c;
  }

  ${(props) =>
    props.loading &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
`;

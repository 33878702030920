import cabinIcon from "assets/images/cabin.svg";
import ButtonLink from "atoms/ButtonLink";
import CollapsibleReservation from "atoms/CollapsibleReservation";
import { InfoBoxWithMarginTop } from "components/Contents/styles/Contents.styled";
import Header from "components/Header";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../provider/LanguageProvider";
import dict from "../utils/translations";

export default function ExtrasPage({ ...props }) {
  const { state, getProperty, getReservation, getUnit, getUnitGroup } =
    useAppContext();

  const location = useLocation();
  const { lang } = useContext(LanguageContext);

  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { user: currentUser } = state;

  const reservation = getReservation(reservationId);
  const property = getProperty(reservation);
  const unit = getUnit(reservation);
  const unitGroup = getUnitGroup(reservation);

  if (currentUser && reservation && property) {
    return (
      <div>
        <Header />

        <InfoBoxWithMarginTop>
          {dict("pages.extrasPageIntro", lang)}
        </InfoBoxWithMarginTop>

        {contents
          .filter(
            (contentItem) =>
              contentItem.id === "food" || contentItem.id === "activities"
          )
          .map((contentItem, index) => (
            <CollapsibleReservation
              key={index}
              reservation={reservation}
              unit={unit}
              unitGroup={unitGroup}
              property={property}
              contentItem={contentItem}
              // isExpanded={true}
              {...contentItem}
            />
          ))}

        <InfoBoxWithMarginTop>
          <ButtonLink
            caption={dict("pages.toReservation", lang)}
            to={"/reservation?reservation=" + reservationId}
            icon={cabinIcon}
          />
        </InfoBoxWithMarginTop>
      </div>
    );
  }
}

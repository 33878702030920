import CollapsibleReservations from "atoms/CollapsibleReservations";
import { ReservationsListData } from "data/ContentOutline";

function ListOfReservations() {
  return (
    <div>
      {ReservationsListData.map((contentItem, index) => (
        <CollapsibleReservations key={index} {...contentItem} />
      ))}
    </div>
  );
}

export default ListOfReservations;

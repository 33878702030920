import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import dict from "utils/translations";
import { H3noP, InfoBox, Px } from "./styles/Contents.styled";

function BookingRateContent() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { getReservation } = useAppContext();
  const reservation = getReservation(reservationId);

  const { lang } = useContext(LanguageContext);

  return (
    <div>
      <InfoBox>
        <H3noP>{reservation.ratePlan.name}</H3noP>
        <Px>{reservation.ratePlan.description}</Px>
      </InfoBox>
      <InfoBox>
        <H3noP>
          {dict("pages.cancellationFee", lang)}{" "}
          {reservation.cancellationFee.name}
        </H3noP>
        <Px>{reservation.cancellationFee.description}</Px>
      </InfoBox>
      <InfoBox>
        <H3noP>
          {dict("pages.noShowFee", lang)} {reservation.cancellationFee.name}
        </H3noP>
        <Px>{reservation.noShowFee.description}</Px>
      </InfoBox>
    </div>
  );
}

export default BookingRateContent;

import { InfoBox, ContentBox } from "./styles/Contents.styled";
import BookedCabins from "./BookedCabins";
import { useAppContext } from "provider/AppProvider";

function ReservationsItem({ reservation }) {
  const { getUnit } = useAppContext();
  const unit = getUnit(reservation);

  return (
    <ContentBox>
      <InfoBox>
        <BookedCabins unit={unit} reservation={reservation} />
      </InfoBox>
    </ContentBox>
  );
}

export default ReservationsItem;

import * as Sentry from "@sentry/browser";

import axios from "axios";
import { rausToast } from "../utils/toastify";
import dict from "../utils/translations";

class checkinService {
  /** we always save and retrieve check-in data from the first reservation in the booking */
  getStatus(bookingNumber) {
    const firstReservationInBooking = `${bookingNumber}-1`;

    return axios
      .get(
        `https://hook.eu1.make.com/b6emoovid9ykc3n59vgfv6bje1dd2u40?reservationId=${firstReservationInBooking}`
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }

  saveCheckInForm(formValues, lang) {
    return axios
      .post(
        `https://hook.eu1.make.com/3ljhb3tg5gtb3gvbnu8lyhfdur6smlrc`,
        JSON.stringify(formValues),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("saveCheckInForm response:", response);
        return response.data;
      })
      .catch(function (error) {
        console.error("saveCheckInForm error:", error);
        Sentry.captureException(error);
        rausToast.error(dict("checkIn.messageCheckInFailed", lang));
        throw new Error("Server returned an error status for saveCheckInForm");
      });
  }
}

export default new checkinService();

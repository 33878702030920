import { clearLocalStorage } from "../utils/clearLocalStorage";
import { LocalStorage } from "../utils/localStorage";

export const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.item };

    case "LOGOUT":
      return {};

    case "CHECK_IN_USER":
      return { ...state, hasCheckedIn: true };

    case "SET_BOOKING":
      return { ...state, booking: action.item };

    case "SET_RESERVATION":
      return {
        ...state,
        reservation: { ...state.reservation, [action.identifier]: action.item },
      };

    case "SET_PROPERTY":
      return {
        ...state,
        property: { ...state.property, [action.identifier]: action.item },
      };

    case "SET_PROPERTY_SERVICE":
      return {
        ...state,
        propertyService: {
          ...state.propertyService,
          [action.identifier]: action.item,
        },
      };

    case "SET_LOCATION_CONTENT":
      return { ...state, locationContent: action.item };

    case "SET_UNIT":
      return {
        ...state,
        unit: { ...state.unit, [action.identifier]: action.item },
      };

    case "SET_UNIT_GROUP":
      return {
        ...state,
        unitGroup: { ...state.unitGroup, [action.identifier]: action.item },
      };

    case "SET_UNIT_GROUP_CONTENT":
      return { ...state, unitGroupContent: action.item };

    case "SET_UNIT_CONTENT":
      return { ...state, cabinContent: action.item };

    case "SET_CABIN":
      return {
        ...state,
        cabin: { ...state.cabin, [action.identifier]: action.item },
      };

    case "SET_CABIN_MANUAL":
      return { ...state, cabinManual: action.item };

    case "SET_EXPANDED":
      const newState = { ...state };
      newState.expanded = { ...state.expanded };
      newState.expanded[action.identifier] = {
        ...state.expanded?.[action.identifier],
      };
      newState.expanded[action.identifier][action.extraType] = action.expanded;

      return newState;

    default:
      return state;
  }
};

export const logout = () => {
  clearLocalStorage(["hasWatchedVideoOnce"]);
  return {
    type: "LOGOUT",
  };
};

export const checkInUser = () => {
  LocalStorage.setItem("hasCheckedIn", true);
  return {
    type: "CHECK_IN_USER",
  };
};

export const setLocation = (item, identifier) => {
  LocalStorage.addItem("location", identifier, item);
  return {
    type: "SET_LOCATION",
    item,
    identifier,
  };
};

export const setUser = (item) => {
  LocalStorage.setItem("user", item);

  return {
    type: "SET_USER",
    item,
  };
};

export const setBooking = (item) => {
  LocalStorage.setItem("booking", item);

  return {
    type: "SET_BOOKING",
    item,
  };
};

export const setReservation = (item, identifier) => {
  LocalStorage.addItem("reservation", identifier, item);

  return {
    type: "SET_RESERVATION",
    item,
    identifier,
  };
};

export const setProperty = (item, identifier) => {
  LocalStorage.addItem("property", identifier, item);

  return {
    type: "SET_PROPERTY",
    item,
    identifier,
  };
};

export const setPropertyService = (item, identifier) => {
  LocalStorage.addItem("property_service", identifier, item);

  return {
    type: "SET_PROPERTY_SERVICE",
    item,
    identifier,
  };
};

export const setLocationContent = (item) => {
  LocalStorage.setItem("location_content", item);

  return {
    type: "SET_LOCATION_CONTENT",
    item,
  };
};

export const setUnit = (item, identifier) => {
  LocalStorage.addItem("unit", identifier, item);

  return {
    type: "SET_UNIT",
    item,
    identifier,
  };
};

export const setUnitGroup = (item, identifier) => {
  LocalStorage.addItem("unit-group", identifier, item);

  return {
    type: "SET_UNIT_GROUP",
    item,
    identifier,
  };
};

export const setUnitGroupContent = (item) => {
  LocalStorage.setItem("unitgroup_content", item);

  return {
    type: "SET_UNIT_GROUP_CONTENT",
    item,
  };
};

export const setcabinContent = (item) => {
  LocalStorage.setItem("unit_content", item);

  return {
    type: "SET_UNIT_CONTENT",
    item,
  };
};

export const setCabin = (item, identifier) => {
  LocalStorage.addItem("cabin", identifier, item);

  return {
    type: "SET_CABIN",
    item,
    identifier,
  };
};

export const setCabinManual = (item) => {
  LocalStorage.setItem("cabin_manual", item);

  return {
    type: "SET_CABIN_MANUAL",
    item,
  };
};

export const setExpanded = (identifier, extraType, expanded) => {
  LocalStorage.addItem("expanded", `${identifier}.${extraType}`, expanded);

  return {
    type: "SET_EXPANDED",
    identifier,
    extraType,
    expanded,
  };
};

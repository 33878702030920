const theme = {
  colors: {
    white: "#fff",
    offWhite: "#FDF9ED",
    black: "#23212C",
    grayDark: "rgba(35, 33, 44, 0.5)",
    gray: "rgba(35, 33, 44, 0.2)",
    grayLight: "rgba(35, 33, 44, 0.1)",
    error: "#B90707",
    primary: "#006434",
    primaryLight: "rgba(6, 89, 54, 0.7)",
    primaryVeryLight: "#C9E7D9",
    lightOrange: "#FCBD1C",
    darkPink: "#B318A4",
    lightBlue: "#A6DFFF",
    darkOrange: "#DD5000",
    lightGreen: "#BDDA0E",
    purple: "#592DB5",
    rosa: "#fee0e0",
  },

  borderRadius: {
    xxsmall: "0.25rem",
    xsmall: "0.625rem",
    small: "0.75rem",
    default: "1rem",
    medium: "1.25rem",
    large: "1.625rem",
    xlarge: "2.5rem",
    xxlarge: "4.5rem",
  },
};

export default theme;

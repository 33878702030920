import styled from "styled-components";
import theme from "../utils/theme";

export const Error = styled.div`
  margin-top: -12px;
  color: ${theme.colors.error};
  margin-bottom: 18px;
`;

export const Warning = styled.div`
  margin-top: -12px;
  color: ${theme.colors.darkOrange};
  margin-bottom: 18px;
`;

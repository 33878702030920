import logo from "assets/images/logo.svg";
import phone from "assets/images/phone_in_talk.svg";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import dict from "utils/translations";
import {
  HeaderWrapper,
  HelpButton,
  Logo,
  StyledLink,
} from "./styles/Header.styled";
function Header() {
  const { lang } = useContext(LanguageContext);

  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const numBookings = parseInt(localStorage.getItem("numBookings"), 10) || 0;

  const logoLink =
    numBookings > 1 ? "/" : "/reservation?reservation=" + reservationId;
  const [sticky, setSticky] = useState(true);

  return (
    <HeaderWrapper sticky={sticky}>
      <StyledLink to={logoLink}>
        <Logo src={logo} alt="logo" />
      </StyledLink>
      {/* <button onClick={toggleLanguage}>{lang}</button> */}
      <HelpButton href="tel:+4915735987706">
        {dict("pages.contact", lang)}
        <img src={phone} alt="phone" />
      </HelpButton>
    </HeaderWrapper>
  );
}

export default Header;

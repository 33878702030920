import * as Sentry from "@sentry/browser";
import axios from "axios";

const METADATA_URL =
  process.env.REACT_APP_METADATA_API_ENPOINT + "companion-contents/";
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

class ContentService {
  getLocationContent(spotCode, lang) {
    return axios
      .get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          locale: "en",
          populate: "localizations",
          "filters[locations][spot_code][$eq]": spotCode,
        },
      })
      .then((response) => {
        let contents = response.data.data.map((content) => {
          // look for localized content
          if (lang !== "en") {
            let localizedContent = content.attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === lang;
              }
            );
            return localizedContent
              ? localizedContent.attributes
              : content.attributes;
          } else {
            return content.attributes;
          }
        });
        return contents;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }

  getCabinContent(unitId, lang) {
    // console.log('getcabinContent lang', lang)
    return axios
      .get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          locale: "en",
          populate: "localizations",
          "filters[cabins][unit_id][$eq]": unitId,
        },
      })
      .then((response) => {
        let contents = response.data.data.map((content) => {
          // look for localized content
          if (lang !== "en") {
            // console.log(' getCabinContent', lang)
            let localizedContent = content.attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === lang;
              }
            );
            // console.log(' getCabinContent localizedContent', localizedContent)
            return localizedContent
              ? localizedContent.attributes
              : content.attributes;
          } else {
            // console.log(' en content', content.attributes)
            return content.attributes;
          }
        });
        // console.log('contents', contents)
        return contents;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }

  getUnitGroupContent(unitGroupId, lang) {
    return axios
      .get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          locale: "en",
          populate: "localizations",
          "filters[unit_groups][unit_group_id][$eq]": unitGroupId,
        },
      })
      .then((response) => {
        let contents = response.data.data.map((content) => {
          // look for localized content
          if (lang !== "en") {
            let localizedContent = content.attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === lang;
              }
            );
            return localizedContent
              ? localizedContent.attributes
              : content.attributes;
          } else {
            return content.attributes;
          }
        });
        return contents;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }
}

export default new ContentService();

import { toast } from "react-toastify";
import errorIconSrc from "assets/images/error.svg";
import infoIconSrc from "assets/images/info.svg";
import warnIconSrc from "assets/images/warn.svg";
import theme from "./theme";
import Icon from "../atoms/Icon";
import styled from "styled-components";

const ErrorIcon = () => <img src={errorIconSrc} alt="error" />;
const WarningIcon = () => <img src={warnIconSrc} alt="warning" />;
const InfoIcon = () => <img src={infoIconSrc} alt="info" />;

const SuccessIcon = () => (
  <Icon
    type={"check"}
    style={{ color: theme.colors.primary }}
    className="material-icons"
  />
);
const CloseButtonContainer = styled.div`
  opacity: 0.3;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -8px;
`;
const CloseButton = ({ closeToast }) => (
  <CloseButtonContainer onClick={closeToast}>
    <Icon type={"close"} className="material-icons" />
  </CloseButtonContainer>
);

export const rausToast = {
  ...toast,
  success: (message, options) =>
    toast.success(message, {
      icon: SuccessIcon,
      closeButton: <CloseButton />,
      ...options,
    }),
  error: (message, options) =>
    toast.error(message, {
      icon: ErrorIcon,
      closeButton: <CloseButton />,
      ...options,
    }),
  warning: (message, options) =>
    toast.warning(message, {
      icon: WarningIcon,
      closeButton: <CloseButton />,
      ...options,
    }),
  info: (message, options) =>
    toast.info(message, {
      icon: InfoIcon,
      closeButton: <CloseButton />,
      ...options,
    }),
};

import * as ReactRouter from "react-router-dom";
import { Route } from "react-router-dom";
import AfterStay from "../pages/AfterStay";
import BookExtraConfirmation from "../pages/BookExtraConfirmation";
import BookExtras from "../pages/BookExtras";
import BookingRate from "../pages/BookingRate";
import CabinManualTopic from "../pages/CabinManualTopic";
import CabinRules from "../pages/CabinRules";
import CheckIn from "../pages/CheckIn";
import Directions from "../pages/Directions";
import Doors from "../pages/Doors";
import Electricity from "../pages/Electricity";
import Emergency from "../pages/Emergency";
import ExtrasPage from "../pages/ExtrasPage";
import ExtraSubpage from "../pages/ExtraSubpage";
import Fireplace from "../pages/Fireplace";
import Generator from "../pages/Generator";
import Grill from "../pages/Grill";
import Heating from "../pages/Heating";
import HelpingHandGuide from "../pages/HelpingHandGuide";
import Hottub from "../pages/Hottub";
import Loading from "../pages/Loading";
import Login from "../pages/Login";
import Overview from "../pages/Overview";
import Packlist from "../pages/Packlist";
import RegionalTips from "../pages/RegionalTips";
import Reservation from "../pages/Reservation";
import Reservations from "../pages/Reservations";
import Safety from "../pages/Safety";
import Sauna from "../pages/Sauna";
import Stove from "../pages/Stove";
import Test from "../pages/Test";
import Toilet from "../pages/Toilet";
import UrgentFaqs from "../pages/UrgentFaqs";
import Welcome from "../pages/Welcome";
import { ProtectedRoute } from "./ProtectedRoute";

export function Routes() {
  return (
    <ReactRouter.Routes>
      <Route
        path="/extras"
        element={
          <ProtectedRoute>
            <ExtrasPage />
          </ProtectedRoute>
        }
      />
      <Route path="/booking/details" element={<BookingRate />} />
      <Route path="/welcome/" element={<Welcome />} />
      <Route path="/loading/" element={<Loading />} />
      <Route path="/cabin-rules/" element={<CabinRules />} />
      <Route path="/test/" element={<Test />} />
      <Route path="/after-stay/" element={<AfterStay />} />
      <Route path="/heating/" element={<Heating />} />
      <Route path="/safety" element={<Safety />} />
      <Route path="/helping_hand_guide" element={<HelpingHandGuide />} />
      <Route path="/urgent_faqs" element={<UrgentFaqs />} />
      <Route path="/overview_where_to_find_what" element={<Overview />} />
      <Route path="/doors-windows" element={<Doors />} />
      <Route path="/electricity_water" element={<Electricity />} />
      <Route path="/stove" element={<Stove />} />
      <Route path="/generator" element={<Generator />} />
      <Route path="/grill" element={<Grill />} />
      <Route path="/toilet" element={<Toilet />} />
      <Route path="/sauna" element={<Sauna />} />
      <Route path="/hottub" element={<Hottub />} />
      <Route path="/fireplace" element={<Fireplace />} />
      <Route path="/emergency" element={<Emergency />} />
      <Route path="/directions" element={<Directions />} />
      <Route path="/packliste" exact element={<Packlist />} />
      <Route path="/tips" exact element={<RegionalTips />} />
      <Route path="/book-extras" element={<BookExtras />} />
      <Route path="/extra" element={<ExtraSubpage />} />
      <Route path="/extra/book" element={<BookExtraConfirmation />} />
      <Route path="/check-in/" element={<CheckIn />} />
      <Route path="/" exact element={<Reservations />} />
      <Route path="/reservation" element={<Reservation />} />
      <Route path="/manual/:topicSlug" element={<CabinManualTopic />} />
      <Route path="/login" element={<Login />} />
    </ReactRouter.Routes>
  );
}

import { contents } from "data/ContentOutline";
import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";

function Generator() {
  const { lang } = useContext(LanguageContext);

  return (
    <PageLayout
      isHeaderSubPageVisible={contents[1].id === "myStay"}
      title={dict("pages.generator", lang)}
      subTitle={dict("contentItems.myStay.title", lang)}
      contentId="generator"
    />
  );
}

export default Generator;

export const calculateTotalPrice = (
  amount,
  count,
  quantity,
  isQuantityRequired,
  pricingUnit,
  availabilityMode
) => {
  let total = amount.grossAmount;
  if (isQuantityRequired) {
    total = (amount.grossAmount / count) * quantity; // for case 2 and case 4
  }

  if (
    pricingUnit === "Person" &&
    (availabilityMode === "Daily" || availabilityMode === "Arrival")
  ) {
    total = amount.grossAmount / count;
  }

  return `${total}€`;
};

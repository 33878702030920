import set from "lodash.set";

export const LocalStorage = {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  addItem(key, path, value) {
    let items = this.getItem(key) || {};
    set(items, path, value);

    localStorage.setItem(key, JSON.stringify(items));
  },

  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },
};

import logo from "assets/images/logo.svg";
import phone from "assets/images/phone_in_talk.svg";
import { InfoBox, Text } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";
import {
  HeaderWrapper,
  HelpButton,
  Logo,
  StickyWrapper,
  StyledLink,
} from "components/styles/Header.styled";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import getCompanionContent from "utils/content";
import dict from "utils/translations";

const PageLayout = ({
  isHeaderSubPageVisible,
  title,
  subTitle,
  content,
  contentId,
  children,
}) => {
  const { state } = useAppContext();

  const locationContent = state.locationContent;
  const cabinContent = state.cabinContent;
  const unitGroupContent = state.unitGroupContent;
  const { lang } = useContext(LanguageContext);

  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const numBookings = parseInt(localStorage.getItem("numBookings"), 10) || 0;
  const logoLink =
    numBookings > 1 ? "/" : "/reservation?reservation=" + reservationId;

  const contentItem = getCompanionContent(
    contentId,
    locationContent,
    unitGroupContent,
    cabinContent
  );

  return (
    <div>
      <HeaderWrapper sticky={false}>
        <StyledLink to={logoLink}>
          <Logo src={logo} alt="logo" />
        </StyledLink>
        {/* <button onClick={toggleLanguage}>{lang}</button> */}
        <HelpButton href="tel:+4915735987706">
          {dict("pages.contact", lang)}
          <img src={phone} alt="phone" />
        </HelpButton>
      </HeaderWrapper>
      <StickyWrapper>
        {isHeaderSubPageVisible ? (
          <HeaderSubpage h5Text={subTitle} h2Text={title} />
        ) : null}
      </StickyWrapper>
      <InfoBox>
        {children ? (
          children
        ) : (
          <Text
            className="subpageContent"
            dangerouslySetInnerHTML={{
              __html: content ? content : contentItem.content,
            }}
          />
        )}
      </InfoBox>
    </div>
  );
};

export default PageLayout;

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "atoms/ScrollToTop";
import GlobalFonts from "pages/styles/Fonts";
import { GlobalStyles } from "pages/styles/Global";
import { AppProvider } from "provider/AppProvider";
import LanguageProvider from "provider/LanguageProvider";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PostHogPageviewTracker from "./PostHogPageviewTracker";
import { ToastifyStyles } from "./pages/styles/Toastify";
import { Routes } from "./routes";

const queryClient = new QueryClient();

const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <title>Raus Companion</title>
          <link rel="stylesheet" href="https://use.typekit.net/fco7dci.css" />
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/25939496.js"
          ></script>
          <meta name="robots" content="noindex" />
          <link rel="shortcut icon" href="/favicon.ico" />
        </HelmetProvider>
        <GlobalStyles />
        <ToastifyStyles />
        <GlobalFonts />
        <ScrollToTop />
        <PostHogPageviewTracker />
        <AppProvider>
          <LanguageProvider>
            <Routes />
          </LanguageProvider>
        </AppProvider>
        <ToastContainer />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;

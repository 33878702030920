import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_ENPOINT;
const METADATA_URL = process.env.REACT_APP_METADATA_API_ENPOINT + "cabins/";
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

class UnitService {
  getPropertyByPath(path, locale = "en") {
    var headers = authHeader();
    headers["Accept-Language"] = locale;
    return axios.get(API_URL + path, {
      headers: headers,
    });
  }

  getCabin(unitId, locale = "en") {
    return axios
      .get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          locale: locale,
          populate:
            "parking,coordinates,overview_image.image_mobile,localizations.revault_location_id",
          "filters[unit_id][$eq]": unitId,
        },
      })
      .then((response) => {
        if (locale !== "en") {
          let enAttributes =
            response.data.data[0].attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === "en";
              }
            );
          // console.log(" enAttributes.attributes", enAttributes.attributes);
          response.data.data[0].attributes.revault_location_id =
            enAttributes.attributes.revault_location_id;
          // return enAttributes ? enAttributes.attributes : response.attributes
        }
        // console.log(" response", response);
        return response;
      });
  }
}

export default new UnitService();

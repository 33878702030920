import BookingRateContent from "components/Contents/BookingRateContent";
import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "../utils/translations";

function BookingRate() {
  const { lang } = useContext(LanguageContext);

  return (
    <div>
      <PageLayout
        isHeaderSubPageVisible={true}
        title={dict("pages.rate", lang)}
        subTitle={dict("contentItems.myStay.title", lang)}
      />
      <BookingRateContent />
    </div>
  );
}

export default BookingRate;

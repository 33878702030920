import Logout from "atoms/Logout";
import BasicFacts from "components/Contents/BasicFacts";
import Header from "components/Header";
import ReservationContentSections from "components/ReservationContentSections";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Reservation({ ...props }) {
  const {
    state,
    getCabin,
    getProperty,
    getReservation,
    getUnit,
    getUnitGroup,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { user: currentUser } = state;

  const reservation = getReservation(reservationId);
  const property = getProperty(reservation);
  const unit = getUnit(reservation);
  const unitGroup = getUnitGroup(reservation);
  const cabin = getCabin(reservation);

  // console.log("currentUser", currentUser);
  // console.log("reservation", reservation);
  // console.log("property", property);

  useEffect(() => {
    // console.log("useEffect");
    // console.log("EU currentUser", currentUser);
    // console.log("EU reservation", reservation);
    // console.log("EU property", property);
    if (!currentUser || !reservation || !property) return navigate("/login");
  }, []);

  if (currentUser && reservation && property) {
    return (
      <div>
        <Header />
        <BasicFacts reservation={reservation} cabin={cabin} />
        <ReservationContentSections
          reservation={reservation}
          unit={unit}
          unitGroup={unitGroup}
          property={property}
          cabin={cabin}
          locationContent={state.locationContent}
          cabinContent={state.cabinContent}
          unitGroupContent={state.unitGroupContent}
        />
        <Logout />
      </div>
    );
  }
}

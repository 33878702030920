import * as dayjs from "dayjs";
import { logout, setReservation } from "hooks/useAppReducer";
import { useGetBookingData } from "hooks/useGetBookingData";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requestActivityService from "services/requestActivity.service";
import ServiceService from "services/service.service";
import { calculateTotalPrice } from "utils/calculateTotalPrice";
import { formatPrice } from "utils/formatPrice";
import { priceType } from "utils/priceType";
import checkboxClicked from "../assets/images/check_box.svg";
import checkbox from "../assets/images/check_box_outline_blank.svg";
import {
  BookActions,
  BookConditions,
  ButtonText,
  CardPriceL,
  ExtraBox,
  ExtraBoxS,
  H,
  Note,
  QuantityButtonMinus,
  QuantityButtonPlus,
  QuantityContainer,
  QuantityDisplay,
  TextContainer,
} from "../components/Contents/styles/Contents.styled";
import { Button } from "../components/styles/Button.styled";
import { LanguageContext } from "../provider/LanguageProvider";
import { rausToast } from "../utils/toastify";
import dict from "../utils/translations";

function ExtraCheckout({ extraContentItem, extraTypes, preferredTime }) {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const serviceId = params?.serviceId;

  const { dispatch, getReservation, state } = useAppContext();
  const navigate = useNavigate();

  const reservation = getReservation(reservationId);

  const [currentBookingNumber, setCurrentBookingNumber] = useState(false);
  const { isDone, isFetching } = useGetBookingData(
    state.user,
    currentBookingNumber
  );

  const { lang } = useContext(LanguageContext);
  const bookableService =
    reservation?.bookableServices.find(
      ({ identifier }) => identifier === serviceId
    ) ?? false;

  // const { adults, childrenAges } = reservation;
  // const totalPerson = childrenAges.length + adults;
  const {
    availabilityMode = "",
    amount = { grossAmount: 0 },
    count = 0,
    pricingUnit = "",
    dates = [],
    availabilityQuantity = 0, // Add this line
  } = bookableService || {};

  const isQuantityRequired = useMemo(() => {
    return (
      availabilityMode === "Arrival" &&
      (pricingUnit === "Room" || pricingUnit === "Person")
    );
  }, [availabilityMode, pricingUnit]);

  const [quantity, setQuantity] = useState(
    isQuantityRequired &&
      pricingUnit === "Person" &&
      availabilityMode === "Arrival"
      ? count
      : isQuantityRequired
      ? 1
      : count
  );

  const [buttonClicked, setButtonClicked] = useState(false); // for disabling "loading" button

  const subTotal = useMemo(() => {
    if (availabilityMode === "Daily" && pricingUnit === "Room") {
      // Case 1 availabilityMode="Daily" and pricingUnit="Room"
      if (dates.length > 0 && dates[0].amount && dates[0].amount.grossAmount) {
        return parseFloat(dates[0].amount.grossAmount);
      } else {
        return 0;
      }
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      // Case 3 availabilityMode="Daily" and pricingUnit="Person"
      if (dates.length > 0 && dates[0].amount && dates[0].amount.grossAmount) {
        return parseFloat(dates[0].amount.grossAmount) / count;
      } else {
        return 0;
      }
    } else {
      const qty = isQuantityRequired ? 1 : quantity; // Set quantity to 1 if isQuantityRequired is true
      return parseFloat(
        calculateTotalPrice(
          amount,
          count,
          qty,
          isQuantityRequired,
          pricingUnit,
          availabilityMode
        )
      );
    }
  }, [
    quantity,
    amount,
    count,
    isQuantityRequired,
    pricingUnit,
    availabilityMode,
    dates,
  ]);

  const formattedSubTotal = useMemo(() => {
    if (!subTotal) return "0";
    return subTotal.toFixed(2).replace(".", ",");
  }, [subTotal]);

  const displaySubTotal = useMemo(() => {
    return formatPrice(subTotal);
  }, [subTotal]);

  const totalPrice = useMemo(() => {
    const parsedSubTotal = parseFloat(formattedSubTotal.replace(",", "."));
    let result = 0;

    if (availabilityMode === "Daily" && pricingUnit === "Room") {
      // Case 1 availabilityMode="Daily" and pricingUnit="Room"
      result = parsedSubTotal * dates.length;
    } else if (availabilityMode === "Arrival" && pricingUnit === "Room") {
      // Case 2 availabilityMode="Arrival" and pricingUnit="Room"
      result = parsedSubTotal * quantity;
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      // Case 3 availabilityMode="Daily" and pricingUnit="Person"
      result = parsedSubTotal * dates.length * count;
    } else if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      // Case 4 availabilityMode="Arrival" and pricingUnit="Person"
      result = parsedSubTotal * count * (1 + (quantity - count) / count);
    }

    return result;
  }, [
    formattedSubTotal,
    count,
    quantity,
    availabilityMode,
    pricingUnit,
    dates,
  ]);

  const displayTotalPrice = useMemo(() => {
    return formatPrice(totalPrice);
  }, [totalPrice]);

  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attemptedBooking, setAttemptedBooking] = useState(false);

  const handleCheckboxClick = () => {
    setCheckboxStatus(!checkboxStatus);
    setAttemptedBooking(false);
  };

  const [bookingSuccessful, setBookingSuccessful] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const handleCheckOutClick = async () => {
    setButtonClicked(true);
    setAttemptedBooking(true); // set attemptedBooking to true before setting errorMessage

    // console.log('preferredTime', preferredTime, preferredTime === 'none')
    // console.log('bookableService.type', bookableService.type)
    // console.log('checkboxStatus', checkboxStatus)

    if (
      !checkboxStatus &&
      preferredTime === "none" &&
      bookableService === "Experience-on-request"
    ) {
      setErrorMessage(
        `${dict("contents.conditionsError", lang)}\n${dict(
          "contents.preferredTimeError",
          lang
        )}`
      );
      setButtonClicked(false);
      return;
    } else if (!checkboxStatus) {
      setErrorMessage(dict("contents.conditionsError", lang));
      setButtonClicked(false);
      return;
    } else if (
      preferredTime === "none" &&
      bookableService === "Experience-on-request"
    ) {
      setErrorMessage(dict("contents.preferredTimeError", lang));
      setButtonClicked(false);
      return;
    }

    const dates = bookableService.dates
      .map(({ serviceDate }) => serviceDate)
      .filter((serviceDate) => {
        return bookableService.blockedDates.reduce((allowed, range) => {
          if (!allowed) return false;
          if (range.from && range.to) {
            const comparedDate = dayjs(serviceDate);
            return (
              comparedDate.isBefore(dayjs(range.from)) &&
              comparedDate.isAfter(dayjs(range.to))
            );
          }
          throw new Error("Something went wrong in blocked range");
        }, true);
      });

    if (bookableService.type === "Experience-on-request") {
      const result = await requestActivityService.requestActivity(
        reservationId,
        serviceId,
        preferredTime
      );

      // Handle the result here
      if (result) {
        if (result.status === "RequestCreated") {
          // Adding the modified activityStatus object to bookableService
          bookableService["activityStatus"] = {
            booked_date: null,
            service_code: bookableService.identifier,
            status: "requested",
          };
          reservation?.bookableServices.find((service, i) => {
            if (service.identifier === serviceId) {
              reservation.bookableServices[i] = bookableService;
              //staylonger status
              dispatch(setReservation(reservation, reservationId)); //staylonger wird hier ueberschrieben
              return true; // stop searching
            }
          });
        }
        // const bookingNumber = reservationId.split('-')[0];
        setRequestSuccessful(true);

        // this fires useGetBookingData
        // setCurrentBookingNumber(bookingNumber);
      } else {
        setButtonClicked(false);
      }
    } else {
      let result = await ServiceService.bookService(
        reservationId,
        serviceId,
        quantity,
        dates
      );
      if (result) {
        const bookingNumber = reservationId.split("-")[0];
        setBookingSuccessful(true);
        // this fires useGetBookingData
        setCurrentBookingNumber(bookingNumber);
      } else {
        setButtonClicked(false);
        rausToast.warning(dict("login.expired", lang));
        dispatch(logout());
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (bookingSuccessful) {
      navigate(
        `/extra?serviceId=${serviceId}&type=booked&reservation=${reservationId}&extraContentItem=${extraContentItem}&extraTypes=${encodeURIComponent(
          extraTypes
        )}`
      );
    }
  }, [bookingSuccessful]);

  useEffect(() => {
    if (requestSuccessful) {
      navigate(
        `/extra?serviceId=${serviceId}&type=requested&reservation=${reservationId}&extraContentItem=${extraContentItem}&extraTypes=${encodeURIComponent(
          extraTypes
        )}`
      );
    }
  }, [requestSuccessful]);

  // useEffect(() => {
  //   console.log('ExtraCheckout effect isDone', isDone)
  // }, [isDone])

  // useEffect(() => {
  //   console.log('ExtraCheckout effect isFetching', isFetching)
  // }, [isFetching])

  // console.log('in ExtraCheckout isDone', isDone)
  // console.log('in ExtraCheckout isFetching', isFetching)

  //if quantity left empty for unlimited (default), the quantity can go up to 10, tbd with BIZ DEV how much we can put here or even make unlimited
  const incrementQuantity = () => {
    if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + count;
        if (availabilityQuantity === null) {
          return newQuantity <= 10 ? newQuantity : prevQuantity;
        } else {
          return newQuantity <= availabilityQuantity
            ? newQuantity
            : prevQuantity;
        }
      });
    } else {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;
        if (availabilityQuantity === null) {
          return newQuantity <= 7 ? newQuantity : prevQuantity;
        } else {
          return newQuantity <= availabilityQuantity
            ? newQuantity
            : prevQuantity;
        }
      });
    }
  };

  const decrementQuantity = () => {
    if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      setQuantity((prevQuantity) => {
        if (prevQuantity - count >= count) {
          return prevQuantity - count;
        } else {
          return prevQuantity;
        }
      });
    } else {
      setQuantity((prevQuantity) => {
        if (prevQuantity - 1 >= 1) {
          return prevQuantity - 1;
        } else {
          return prevQuantity;
        }
      });
    }
  };

  const renderSpecificTextField = () => {
    if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Room" &&
      availabilityQuantity === 1
    ) {
      return null;
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Room" &&
      availabilityQuantity !== 1 &&
      bookableService.dates[0].amount.grossAmount != 0 &&
      bookableService.type !== "Experience-on-request"
    ) {
      return <p>{dict("contents.quantitySpecific2", lang)}</p>;
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Person" &&
      availabilityQuantity === 1 &&
      !["E_BIKE", "XP_BIKE_X", "AD_BIK_DS"].includes(bookableService.code)
    ) {
      return (
        <p>
          {"("}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}
          {")"}
        </p>
      );
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Person" &&
      availabilityQuantity !== 1
    ) {
      return (
        <p>
          {"("}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}
          {")"}
          <br />
          {bookableService.type !== "Experience-on-request" &&
            !["E_BIKE", "XP_BIKE_X", "AD_BIK_DS"].includes(
              bookableService.code
            ) &&
            dict("contents.quantitySpecific4", lang)}
        </p>
      );
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      return (
        <p>
          {"("}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}{" "}
          {dict("contents.multiply", lang)}
          {dates.length}{" "}
          {dates.length === 1
            ? dict("contents.night", lang)
            : dict("contents.nights", lang)}
          {")"}
        </p>
      );
    } else if (availabilityMode === "Daily" && pricingUnit === "Room") {
      return (
        <p>
          {"("}
          {dates.length}{" "}
          {dates.length === 1
            ? dict("contents.night", lang)
            : dict("contents.nights", lang)}
          {")"}
        </p>
      );
    }
    return null;
  };

  const isMinusDisabled = quantity === (pricingUnit === "Person" ? count : 1);

  const renderQuantity = () => {
    // Case 2 and Case 4 if availabilityQuantity=1, no option to choose the quantity of a product, calculates automatically
    // valid for exceptions such as VDS, Meditation, Share Now
    if (
      bookableService.type !== "Experience-on-request" &&
      ((availabilityMode === "Arrival" && pricingUnit === "Room") ||
        (pricingUnit === "Person" && availabilityQuantity !== 1))
    ) {
      return <p>{dict("contents.moreQuantity", lang)}</p>;
    }
    return null;
  };

  const isExcludedServiceCodeForQuantity = (code) => {
    return ["E_BIKE", "XP_BIKE_X", "AD_BIK_DS", "LATE_CHE"].includes(code);
  };

  const shouldRenderQuantity = useMemo(() => {
    return (
      isQuantityRequired &&
      availabilityQuantity !== 1 &&
      bookableService.type !== "Experience-on-request" &&
      bookableService.dates[0].amount.grossAmount !== 0 &&
      !isExcludedServiceCodeForQuantity(bookableService.code)
    );
  }, [isQuantityRequired, availabilityQuantity, bookableService]);

  return (
    <div>
      {!!bookableService && (
        <div>
          {shouldRenderQuantity &&
            availabilityQuantity !== 1 &&
            bookableService.type !== "Experience-on-request" && (
              <ExtraBox>
                <H>{renderQuantity()}</H>
                <QuantityContainer>
                  <QuantityButtonMinus
                    onClick={decrementQuantity}
                    type="button"
                    disabled={isMinusDisabled}
                  ></QuantityButtonMinus>
                  <QuantityDisplay>{quantity}</QuantityDisplay>
                  <QuantityButtonPlus
                    onClick={incrementQuantity}
                    type="button"
                  ></QuantityButtonPlus>
                </QuantityContainer>
              </ExtraBox>
            )}
          <ExtraBox>
            <H>{dict("contents.exPriceFinal", lang)}</H>
            {bookableService.amount.grossAmount !== 0 && (
              <ExtraBoxS>
                {displaySubTotal}€ {priceType(bookableService, lang)}
              </ExtraBoxS>
            )}
            <Note>{renderSpecificTextField()}</Note>
            <ExtraBoxS>
              <CardPriceL>
                {dict("contents.totalPriceH", lang)} {displayTotalPrice}€{" "}
              </CardPriceL>
              {bookableService.amount.grossAmount !== 0 && (
                <p>{dict("contents.paymentExtra", lang)}</p>
              )}
            </ExtraBoxS>
          </ExtraBox>

          <BookConditions>
            <img
              src={checkboxStatus ? checkboxClicked : checkbox}
              alt={checkboxStatus ? "checkClicked" : "check"}
              onClick={handleCheckboxClick}
            />
            <TextContainer>
              {dict("contents.bookConditions", lang)}
              <a
                href={
                  lang === "de"
                    ? "https://www.raus.life/de/agb-und-widerrufsrecht/"
                    : "https://www.raus.life/terms-and-conditions/"
                }
                target="_blank"
                rel="noreferrer"
              >
                {dict("contents.conditions", lang)}
              </a>
            </TextContainer>
          </BookConditions>
          {(!checkboxStatus || preferredTime === "none") &&
            attemptedBooking && (
              <ExtraBox className="error">{errorMessage}</ExtraBox>
            )}
          <BookActions>
            {/* using $loading: transient-props - https://stackoverflow.com/a/64157580 */}
            <Button
              onClick={handleCheckOutClick}
              className="primary"
              $loading={buttonClicked}
            >
              <ButtonText>
                {buttonClicked
                  ? dict("contents.waitExtraBooking", lang)
                  : dict("contents.bookExtraButton", lang)}
              </ButtonText>
            </Button>
          </BookActions>
        </div>
      )}
    </div>
  );
}

export default ExtraCheckout;

import { useContext, useEffect, useState } from "react";
import down from "../assets/images/keyboard_arrow_down.svg";
import up from "../assets/images/keyboard_arrow_up.svg";
import BeforeTrip from "../components/Contents/BeforeTrip";
import Checkout from "../components/Contents/Checkout";
import ExtrasSection from "../components/Contents/ExtrasSection";
import TravelInfo from "../components/Contents/TravelInfo";
// import Extras from '../components/Contents/Extras'
import logo from "assets/images/logo.svg";
import ButtonDiv from "atoms/ButtonDiv";
import Modal from "react-modal";
import { toast } from "react-toastify";
import HelpSecurity from "../components/Contents/HelpSecurity";
import {
  ContentBox,
  InfoBox,
} from "../components/Contents/styles/Contents.styled";
import IFrameWrapper from "../components/IFrameWrapper";
import {
  HeaderSubpageWrapper,
  Logo,
} from "../components/styles/Header.styled.js";
import { LanguageContext } from "../provider/LanguageProvider";
import getCompanionContent from "../utils/content";
import dict from "../utils/translations";
import {
  ContentWrapper,
  Icon,
  Item,
  Tile,
  TileWrapper,
  Title,
  TitleWrapper,
} from "./styles/Collapsible.styled";

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    zIndex: 2,
    background: "#fdf9ed",
    border: 0,
  },
  content: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "0 0 0",
    zIndex: 3,
    borderRadius: 0,
    border: "none",
    background: "#fdf9ed",
  },
};

const modalCloseButton = {
  cursor: "pointer",
  borderRadius: "14px",
  border: "none",
  padding: "13px 12px",
  height: "48px",
  color: "#ffffff",
  background: "#23212c",
};

function CollapsibleReservation({
  reservation,
  unit,
  unitGroup,
  property,
  unitGroupContent,
  locationContent,
  cabinContent,
  cabin,
  contentItem,
  isExpanded = false,
  ...props
}) {
  // console.log("CollapsibleReservation", reservation);
  // console.log("CollapsibleReservation unit", unit);
  // console.log("CollapsibleReservation cabin", cabin);
  // console.log("CollapsibleReservation props", props);
  const [expanded, setExpanded] = useState(
    localStorage.getItem(`expanded-${props.id}-${reservation.identifier}`) ===
      "true" || isExpanded
  );

  useEffect(() => {
    localStorage.setItem(
      `expanded-${props.id}-${reservation.identifier}`,
      expanded
    );
  }, [expanded]);

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    window.addEventListener(
      "message",
      (evt) => {
        console.log("message", evt);
        if (
          evt.origin === "https://app.revaultapp.co" &&
          (evt.data === "success" || evt.data === "error")
        ) {
          toast.success(dict("contentItems.myStay.reportedIncident", lang));
          closeModal();
        }
      },
      { once: true }
    );
  }

  function closeModal() {
    setIsOpen(false);
  }

  // data prep for beforetrip
  if (props.id === "beforeTrip") {
    let packliste = getCompanionContent(
      "packliste",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    let tips = getCompanionContent(
      "region_tips",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (props.id === "beforeTrip" && packliste) {
      contentItem.contents["packliste"] = packliste;
    }
    if (props.id === "beforeTrip" && tips) {
      contentItem.contents["tips"] = tips;
    }
  }

  function isServiceBooked(bookedServices, serviceCode) {
    return (
      Array.isArray(bookedServices) &&
      bookedServices.some((service) =>
        [serviceCode, "SAUNA"].includes(service.code)
      )
    );
  }
  function isServiceFreeInLocation({ serviceCode, spotCode }) {
    const freeServicesByLocationsMap = {
      SPOT4: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT13: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT14: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT29: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    };
    if (!freeServicesByLocationsMap?.[spotCode]) return false;
    return freeServicesByLocationsMap[spotCode].includes(serviceCode);
  }

  function shouldShowSauna({ bookedServices, spotCode }) {
    const saunaServiceCode = "AD_SAU_DS";
    if (isServiceFreeInLocation({ serviceCode: saunaServiceCode, spotCode }))
      return true;
    return isServiceBooked(bookedServices, saunaServiceCode);
  }

  function shouldShowHotTub({ bookedServices, spotCode }) {
    const hotTubServiceCode = "AD_HOT_DS";
    if (isServiceFreeInLocation({ serviceCode: hotTubServiceCode, spotCode }))
      return true;
    return isServiceBooked(bookedServices, hotTubServiceCode);
  }

  if (props.id === "myStay") {
    // aka "your cabin"
    const rules = getCompanionContent(
      "cabin-rules",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (rules) {
      contentItem.contents["cabin-rules"] = rules;
    }

    const electricity = getCompanionContent(
      "electricity_water",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (electricity) {
      contentItem.contents["electricity_water"] = electricity;
    }

    const heating = getCompanionContent(
      "heating",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (heating) {
      contentItem.contents["heating"] = heating;
    }

    const stove = getCompanionContent(
      "stove",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (stove) {
      contentItem.contents["stove"] = stove;
    }

    const generator = getCompanionContent(
      "generator",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (generator) {
      contentItem.contents["generator"] = generator;
    }

    const sauna = getCompanionContent(
      "sauna",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (sauna) {
      contentItem.contents["sauna"] = sauna;
    }

    const hottub = getCompanionContent(
      "hottub",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (hottub) {
      contentItem.contents["hottub"] = hottub;
    }

    const doors = getCompanionContent(
      "doors-windows",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (doors) {
      contentItem.contents["doors-windows"] = doors;
    }

    const toilet = getCompanionContent(
      "toilet",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (toilet) {
      contentItem.contents["toilet"] = toilet;
    }

    const grill = getCompanionContent(
      "grill",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (grill) {
      contentItem.contents["grill"] = grill;
    }

    const overview = getCompanionContent(
      "overview_where_to_find_what",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (overview) {
      contentItem.contents["overview_where_to_find_what"] = overview;
    }
  }

  //data prep for security and emergency
  if (props.id === "help") {
    const emergency = getCompanionContent(
      "emergency",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (emergency) {
      contentItem.contents["emergency"] = emergency;
    }

    const safety = getCompanionContent(
      "safety",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (safety) {
      contentItem.contents["safety"] = safety;
    }

    const helpingHandGuide = getCompanionContent(
      "helping_hand_guide",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (helpingHandGuide) {
      contentItem.contents["helping_hand_guide"] = helpingHandGuide;
    }

    const urgentFAQs = getCompanionContent(
      "urgent_faqs",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (urgentFAQs) {
      contentItem.contents["urgent_faqs"] = urgentFAQs;
    }
  }

  //data prep for checkout
  if (props.id === "checkout") {
    const checkout = getCompanionContent(
      "checkout",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (checkout) {
      contentItem.contents["checkout"] = checkout;
    }
  }

  //show only if cabinManual content exists
  if (props.id === "cabinManual") {
    let cabinManual = JSON.parse(localStorage.getItem("cabin_manual"));
    contentItem.contents = cabinManual;
  }

  const { lang } = useContext(LanguageContext);

  const reservationArrivalDate = new Date(reservation.arrival);
  const currentDate = new Date();

  // Calculate the difference between the reservation arrival date and the current date in milliseconds
  const timeDiff = reservationArrivalDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  return (
    <div id={props?.id}>
      {(contentItem.contents && Object.keys(contentItem.contents).length > 0) ||
      contentItem.showAlways ? (
        <Tile>
          <Item>
            <TileWrapper>
              <Title onClick={() => setExpanded(!expanded)}>
                <TitleWrapper>
                  <img src={props.icon} alt="icon" />
                  {dict(`contentItems.${props.id}.title`, lang)}
                </TitleWrapper>
                <Icon>
                  {" "}
                  {expanded ? (
                    <img src={up} alt="up-icon" />
                  ) : (
                    <img src={down} alt="down-icon" />
                  )}{" "}
                </Icon>
              </Title>
            </TileWrapper>
            <ContentWrapper open={expanded}>
              {props.id === "beforeTrip" && unit ? (
                <BeforeTrip
                  unit={unit}
                  property={property}
                  cabin={cabin}
                  contents={contentItem.contents}
                  reservation={reservation}
                  daysDiff={daysDiff}
                />
              ) : props.id === "myStay" ? (
                !cabin ? (
                  <>{dict("contents.noCabinAssigned", lang)}</>
                ) : (
                  <>
                    <TravelInfo
                      contents={contentItem.contents}
                      cabin={cabin}
                      showSauna={shouldShowSauna({
                        bookedServices: reservation.bookedServices,
                        spotCode: property.identifier,
                      })}
                      showHotTub={shouldShowHotTub({
                        bookedServices: reservation.bookedServices,
                        spotCode: property.identifier,
                      })}
                    />
                    {cabin.revault_location_id ? (
                      <ContentBox>
                        <InfoBox>
                          <ButtonDiv
                            onClick={openModal}
                            caption={dict(
                              "contentItems.myStay.reportIncident",
                              lang
                            )}
                          ></ButtonDiv>
                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={modalStyle}
                            contentLabel=""
                          >
                            <HeaderSubpageWrapper
                              style={{ padding: "10px 16px 0px" }}
                            >
                              <Logo src={logo} alt="logo" />
                              <button
                                onClick={closeModal}
                                style={modalCloseButton}
                              >
                                {dict("atoms.close", lang)}
                              </button>
                            </HeaderSubpageWrapper>
                            <IFrameWrapper
                              locationId={cabin.revault_location_id}
                              reservationId={reservation.identifier}
                            ></IFrameWrapper>
                          </Modal>
                        </InfoBox>
                      </ContentBox>
                    ) : (
                      ""
                    )}
                  </>
                )
              ) : props.id === "food" ? (
                <ExtrasSection
                  contents={contentItem.contents}
                  reservation={reservation}
                  extraContentItem="food"
                  extraTypes={["F&B"]}
                />
              ) : props.id === "activities" ? (
                <ExtrasSection
                  contents={contentItem.contents}
                  reservation={reservation}
                  extraContentItem="activity"
                  extraTypes={[
                    "Experience-on-request",
                    "Experiences",
                    "Mobility",
                    "Additional",
                    "Late-Checkout",
                  ]}
                />
              ) : props.id === "checkout" ? (
                <Checkout contents={contentItem.contents} cabin={cabin} />
              ) : props.id === "help" ? (
                <HelpSecurity
                  contents={contentItem.contents}
                  daysDiff={daysDiff}
                />
              ) : null}
            </ContentWrapper>
          </Item>
        </Tile>
      ) : null}
    </div>
  );
}

export default CollapsibleReservation;

import Header from "components/Header";
import ListOfReservations from "components/ListOfReservations";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import ShortWelcome from "./ShortWelcome";
import { useStayStatus } from "../hooks/useStayStatus";
import { useNavigationPath } from "../hooks/useNavigationPath";

export default function Reservations() {
  const [redirect, setRedirect] = useState(null);
  const { state } = useAppContext();
  const buildPath = useNavigationPath();
  const { booking, user: currentUser } = state;

  const stayStatus = useStayStatus({
    departureDateString: booking?.["hydra:member"]?.[0]?.departure,
  });

  useEffect(() => {
    if (!currentUser) setRedirect("/login");
  }, [currentUser]);

  if (redirect) return <Navigate to={redirect} />;

  //check to make sure that booking and booking['hydra:member'] are not null
  if (!booking || !booking["hydra:member"]) {
    return <div>No booking found</div>;
  }
  const reservationId = booking["hydra:member"][0].identifier;
  const amountOfReservations = booking["hydra:totalItems"];

  if (stayStatus?.isAfterStay) {
    return (
      <Navigate
        to={buildPath("/after-stay", {
          reservation: reservationId,
        })}
      />
    );
  }

  if (stayStatus?.isBeforeOrDuringStay && amountOfReservations === 1) {
    return (
      <Navigate
        to={buildPath("/welcome", {
          reservation: reservationId,
        })}
      />
    );
  }

  // let user choose which reservation they'd like to log in if there are multiple
  return (
    <div>
      <Header />
      <ShortWelcome />
      <ListOfReservations />
    </div>
  );
}

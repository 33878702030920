import dayjs from "dayjs";

export function useStayStatus({ departureDateString }) {
  if (!departureDateString) {
    return null;
  }

  const departureDate = dayjs(departureDateString);
  // Customers should have access to instructions on how to lock the handcart, the door and other instructions
  const departureDateWithBuffer = departureDate.add(30, "minutes");
  const now = dayjs();

  const isAfterStay = now.isAfter(departureDateWithBuffer);
  const isBeforeOrDuringStay = now.isSameOrBefore(departureDateWithBuffer);

  return {
    isBeforeOrDuringStay,
    isAfterStay,
  };
}

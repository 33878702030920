export default function camelize(str) {
  if (!str) {
    return "";
  }
  return str
    .replace("/", "")
    .replace("ü", "u")
    .replace("Ü", "U")
    .replace("ä", "a")
    .replace("Ä", "A")
    .replace("ö", "o")
    .replace("Ö", "O")
    .replace("ß", "ss")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

import {
  setBooking,
  setCabin,
  setcabinContent,
  setLocation,
  setLocationContent,
  setProperty,
  setPropertyService,
  setReservation,
  setUnit,
  setUnitGroup,
  setUnitGroupContent,
} from "hooks/useAppReducer";
import { useUpdateStayLongerData } from "hooks/useUpdateStayLongerData";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import BookingService from "../services/booking.service";
import ContentService from "../services/content.service";
import PropertyService from "../services/property.service";
import requestActivityService from "../services/requestActivity.service";
import {
  default as ServiceService,
  default as serviceService,
} from "../services/service.service";
import UnitService from "../services/unit.service";
import UnitGroupService from "../services/unitGroup.service";

export const useGetBookingData = (user, bookingNumber) => {
  const { dispatch, getReservation } = useAppContext();

  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);

  const [currentReservationId, setCurrentReservationId] = useState(null);
  useUpdateStayLongerData(currentReservationId);

  const getBookingData = async (user, bookingNumber) => {
    setIsFetching(true);

    try {
      const bookingResponse = await BookingService.getReservations(
        bookingNumber
      );
      for (let i = 0; i < bookingResponse.data["hydra:member"].length; i++) {
        let currentReservation = bookingResponse.data["hydra:member"][i];
        let reservationResponse = await BookingService.getReservation(
          currentReservation["@id"]
        );

        // service additions, fetching once as we need it in both, booked and bookable services
        let spotCode = currentReservation.property.replace(
          "/api/properties/",
          ""
        );
        let servicesAdditionsResponse =
          await ServiceService.getServicesAdditions(
            spotCode,
            user.preferredLanguage
          );
        reservationResponse.data["servicesAdditions"] =
          servicesAdditionsResponse;

        // activity requests
        let activityStatusResponse =
          await requestActivityService.getActivityStatusFromHubspot(
            currentReservation.identifier
          );
        reservationResponse.data["activityStatus"] = activityStatusResponse;

        // includedServices
        let includedServices =
          await serviceService.getIncludedServicesOfBookedRatePlan(
            currentReservation.identifier
          );

        // always assume AD_SAUNA to be added to included if SPOT29/Lodge am See
        if (spotCode === "SPOT29") {
          includedServices.push({ serviceId: "SPOT29-AD_SAUNA" });
        }
        reservationResponse.data["includedServices"] = includedServices;

        if (currentReservation.services.length > 0) {
          let bookedServicesResponse =
            await ServiceService.getBookedServicesForReservation(
              currentReservation.identifier,
              servicesAdditionsResponse
            );
          reservationResponse.data["bookedServices"] = bookedServicesResponse;
        } else {
          // Ensure bookedServices is always an array
          reservationResponse.data["bookedServices"] = [];
        }

        // Load bookable services
        let bookableServicesResponse =
          await ServiceService.getBookableServicesForReservation(
            currentReservation.identifier,
            servicesAdditionsResponse,
            activityStatusResponse
          );
        reservationResponse.data["bookableServices"] = bookableServicesResponse;

        // Handle sauna service as booked if it exists in included services
        const saunaIncluded = includedServices.find((service) =>
          [`${spotCode}-AD_SAUNA`].includes(service.serviceId)
        );
        // console.log("saunaIncluded", saunaIncluded);
        if (saunaIncluded) {
          // find sauna in bookable services
          const saunaServiceIndex = reservationResponse.data[
            "bookableServices"
          ].findIndex((service) => ["AD_SAUNA"].includes(service.code));

          // Move sauna service from bookable to booked services
          if (saunaServiceIndex !== -1) {
            const [saunaService] = reservationResponse.data[
              "bookableServices"
            ].splice(saunaServiceIndex, 1);
            reservationResponse.data["bookedServices"].push(saunaService);
          }

          // console.log(
          //   "Updated bookedServices:",
          //   reservationResponse.data["bookedServices"]
          // );
          // console.log(
          //   "Updated bookableServices:",
          //   reservationResponse.data["bookableServices"]
          // );
        }

        let propertyResponse = await PropertyService.getPropertyByPath(
          currentReservation.property
        );

        let locationContent = await ContentService.getLocationContent(
          propertyResponse.data.identifier,
          user.preferredLanguage
        );

        let unitGroupResponse = await UnitGroupService.getPropertyByPath(
          currentReservation.unitGroup
        );
        let unitGroupContent = await ContentService.getUnitGroupContent(
          unitGroupResponse.data.identifier,
          user.preferredLanguage
        );

        let localtionResponse = await PropertyService.getLocation(
          spotCode,
          user.preferredLanguage
        );

        dispatch(
          setReservation(
            reservationResponse.data,
            currentReservation.identifier
          )
        );
        dispatch(
          setPropertyService(JSON.stringify({}), currentReservation.identifier)
        );
        dispatch(
          setProperty(propertyResponse.data, propertyResponse.data.identifier)
        );
        dispatch(setLocationContent(locationContent));
        dispatch(setLocation(localtionResponse, spotCode));
        dispatch(
          setUnitGroup(
            unitGroupResponse.data,
            unitGroupResponse.data.identifier
          )
        );
        dispatch(setUnitGroupContent(unitGroupContent));

        // this fires `getStayLongerData`
        setCurrentReservationId(currentReservation.identifier);

        // let stayLonger = await getStayLongerData(currentReservationId)
        // console.log('stayLonger', stayLonger)

        if (currentReservation.unit) {
          let unitResponse = await UnitService.getPropertyByPath(
            currentReservation.unit,
            user.preferredLanguage
          );

          let cabinResponse = await UnitService.getCabin(
            unitResponse.data.identifier,
            user.preferredLanguage
          );

          let cabinContent = await ContentService.getCabinContent(
            unitResponse.data.identifier,
            user.preferredLanguage
          );

          // let cabinmanualContent = await cabinManualService.getCabinManualContent(
          //   unitResponse.data.identifier,
          //   user.preferredLanguage
          // )

          dispatch(setUnit(unitResponse.data, unitResponse.data.identifier));
          dispatch(
            setCabin(
              cabinResponse.data.data[0].attributes,
              unitResponse.data.identifier
            )
          );
          dispatch(setcabinContent(cabinContent));
          //dispatch(setCabinManual(cabinmanualContent))
        }
      }
      dispatch(setBooking(bookingResponse.data));
      setData(bookingResponse.data);
      setIsDone(true);
    } catch (error) {
      setError(error);
      setIsDone(false);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && bookingNumber && !isFetching) {
      getBookingData(user, bookingNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, bookingNumber]);

  return { data, isDone, isFetching, error };
};

import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import getCompanionContent from "utils/content";
import dict from "utils/translations";

function Safety() {
  const { lang } = useContext(LanguageContext);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { state } = useAppContext();
  const contentItem = getCompanionContent(
    "safety",
    state.locationContent,
    state.unitGroupContent,
    state.cabinContent
  );

  const emergencyPageUrl = "/emergency?reservation=" + reservationId;
  const safetyContent = contentItem.content.replace(
    /%emergencyPage%/,
    emergencyPageUrl
  );

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.safety", lang)}
      subTitle={dict("contentItems.help.title", lang)}
      content={safetyContent}
    />
  );
}

export default Safety;

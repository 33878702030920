import ButtonLink from "atoms/ButtonLink";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";
import { H, InfoBox, P } from "./styles/Contents.styled";
import { useNavigationPath } from "../../hooks/useNavigationPath";

function BookedCabins({ unit, reservation }) {
  const { lang } = useContext(LanguageContext);
  const buildPath = useNavigationPath();

  return (
    <div>
      <InfoBox>
        <H>{dict("contents.where", lang)}</H>
        <P>{unit?.name}</P>
      </InfoBox>
      <ButtonLink
        caption={reservation.identifier}
        to={buildPath("/welcome", { reservation: reservation.identifier })}
      />
    </div>
  );
}

export default BookedCabins;

import { contents } from "data/ContentOutline";
import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import dict from "utils/translations";
import {
  BoxWrapper,
  ContentBox,
  ExternalLink,
  H,
  InfoBox,
  P,
  Text,
} from "../components/Contents/styles/Contents.styled";

function Directions() {
  const { lang } = useContext(LanguageContext);
  const { getReservation, getUnit, getCabin, getProperty } = useAppContext();

  const location = useLocation();
  let params = queryString.parse(location.search);

  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const reservation = getReservation(reservationId);
  const unit = getUnit(reservation);
  const property = getProperty(reservation);
  const cabin = getCabin(reservation);

  // console.log("property", property);
  // console.log("c", cabin);
  // console.log("cabin.use_location_parking", cabin.use_location_parking);

  const isSpot29 = property.identifier === "SPOT29";
  // console.log(property.identifier);

  const reservationArrivalDate = new Date(reservation.arrival);
  const currentDate = new Date();

  // Calculate the difference between the reservation arrival date and the current date in milliseconds
  const timeDiff = reservationArrivalDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  return (
    <PageLayout
      isHeaderSubPageVisible={contents[0].id === "beforeTrip"}
      title={dict("pages.connection", lang)}
      subTitle={dict("contentItems.beforeTrip.title", lang)}
      contentId="directions"
    >
      <Text
        className="subpageContent"
        dangerouslySetInnerHTML={{ __html: unit?.parking?.directions }}
      />
      <Text
        className="subpageContent"
        dangerouslySetInnerHTML={{ __html: cabin?.coordinates?.directions }}
      />
      <BoxWrapper>
        <ContentBox>
          {cabin?.parking || property?.parking ? (
            <InfoBox>
              {daysDiff <= 1000 && (
                <>
                  <H>{dict("contents.parkingCoordinates", lang)}</H>
                  <P>
                    <ExternalLink
                      href={
                        !cabin.use_location_parking && cabin.parking?.url
                          ? cabin.parking?.url
                          : property.parking.url
                      }
                      target="_blank"
                    >
                      {!cabin.use_location_parking && cabin.parking?.url
                        ? cabin.parking.latitude
                        : property.parking.latitude}{" "}
                      {!cabin.use_location_parking && cabin.parking?.url
                        ? cabin.parking.longitude
                        : property.parking.longitude}
                    </ExternalLink>
                  </P>
                </>
              )}
            </InfoBox>
          ) : (
            <InfoBox>
              <H>{dict("contents.parkingCoordinates", lang)}</H>
              <P>{dict("contents.missingCoordinates", lang)}</P>
            </InfoBox>
          )}
          {isSpot29 ? (
            <InfoBox>
              <H>
                {!cabin
                  ? dict("contents.locationTitle", lang)
                  : dict("contents.cabinTitle", lang)}
              </H>
              <P>
                {!cabin || !cabin?.name ? (
                  <>{property.name}</>
                ) : (
                  <>{`${property.name}: ${cabin.name}`}</>
                )}
              </P>
            </InfoBox>
          ) : unit?.coordinates ? (
            <InfoBox>
              {daysDiff <= 1000 ? (
                <H>{dict("contents.cabinCoordinates", lang)}</H>
              ) : null}
              {daysDiff <= 1000 ? (
                <P>
                  <ExternalLink
                    href={
                      cabin?.coordinates?.url
                        ? cabin.coordinates.url
                        : unit.coordinates.url
                    }
                    target="_blank"
                  >
                    {cabin?.coordinates?.latitude
                      ? cabin.coordinates.latitude
                      : unit.coordinates.latitude}{" "}
                    {cabin?.coordinates?.latitude
                      ? cabin.coordinates.longitude
                      : unit.coordinates.longitude}
                  </ExternalLink>
                </P>
              ) : (
                <P>{dict("contents.tbaPlural", lang)}</P>
              )}
            </InfoBox>
          ) : (
            <InfoBox>
              <H>{dict("contents.cabinCoordinates", lang)}</H>
              <P>{dict("contents.tbaPlural", lang)}</P>
            </InfoBox>
          )}
        </ContentBox>
      </BoxWrapper>
    </PageLayout>
  );
}

export default Directions;

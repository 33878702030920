import { logout } from "hooks/useAppReducer";
import { Button, LogoutContainer } from "pages/styles/Login.styled";
import { usePostHog } from "posthog-js/react";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import dict from "utils/translations";

function Logout() {
  const { lang } = useContext(LanguageContext);
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const posthog = usePostHog();

  function logOut() {
    dispatch(logout());
    posthog?.reset();
    navigate("/login");
  }

  return (
    <LogoutContainer>
      <Button type="button" className="light" onClick={logOut}>
        {dict("atoms.logout", lang)}
      </Button>
    </LogoutContainer>
  );
}

export default Logout;

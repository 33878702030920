import camelize from "./camelize";
import dict from "./translations";

export const priceType = (service, lang) => {
  if (service.pricingInfo !== "" && service.pricingInfo !== "Keine") {
    return dict(`pricingInfo.${camelize(service.pricingInfo)}`, lang);
  } else if (service.pricingUnit === "Person") {
    if (service.availabilityMode === "Daily") {
      return dict("contents.extraPerPersonNight", lang);
    } else if (service.availabilityMode === "Arrival") {
      return dict("contents.extraPerPersonStay", lang);
    }
  } else if (service.pricingUnit === "Room") {
    if (service.availabilityMode === "Daily") {
      return dict("contents.extraPerCabinNight", lang);
    } else if (service.availabilityMode === "Arrival") {
      return dict("contents.extraPerCabinStay", lang);
    }
  }
  return "";
};

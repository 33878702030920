import backArrow from "assets/images/back-arrow--outline.svg";
import { useNavigate } from "react-router-dom";
import {
  Button,
  H2,
  H5,
  HeaderTextWrapper,
  TitleWrapper,
} from "./styles/Header.styled";

function HeaderSubpage(props) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (props.overrideBackLink) {
      navigate(props.overrideBackLink);
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <TitleWrapper>
        <Button onClick={handleBackClick}>
          {" "}
          <img src={backArrow} alt="back-arrow" />{" "}
        </Button>
        <HeaderTextWrapper>
          <H5>
            {props.condition} {props.h5Text}
          </H5>
          <H2>{props.h2Text}</H2>
        </HeaderTextWrapper>
      </TitleWrapper>
    </div>
  );
}

export default HeaderSubpage;

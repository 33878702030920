import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { calculateTotalPrice } from "utils/calculateTotalPrice";
import { priceType } from "utils/priceType";
import { serviceImage } from "utils/serviceImage";
import { ContentWrapper, Tile } from "../../atoms/styles/Collapsible.styled";
import { LanguageContext } from "../../provider/LanguageProvider";
import dict from "../../utils/translations";
import ExtrasCard from "../ExtrasCard";
import {
  ExtrasCardWrapper,
  InfoBoxWithMarginBottom,
  P,
} from "./styles/Contents.styled";

dayjs.extend(isBetween);

function BookableExtras({ extraContentItem, extraTypes, ...props }) {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const { getReservation } = useAppContext();
  const reservation = getReservation(reservationId);

  const { lang } = useContext(LanguageContext);
  const now = dayjs();
  const arrival = dayjs(reservation.arrival);
  const { hRegularRef } = props;
  const [hasHRegularContent, setHasHRegularContent] = useState(true);

  // todo make this also handle non ava anymore
  // turn array into null
  // Filter the reservation.bookableServices list to get the available extras that match the given extraTypes
  // exclude late dep or early arr or pets or sharenow
  const filteredServices = reservation.bookableServices?.filter((service) => {
    // console.log("service", service);
    const { type, activityStatus, code, identifier } = service;
    const spotCode = identifier.split("-")[0];
    const isInExtraTypes = extraTypes.indexOf(type) > -1;
    const excludeStatuses = ["requested", "booked", "notBooked"];
    const enableSpotCodes = ["DEMO", "COMM_TEST", "SPOT13", "SPOT14"];
    const hasExcludedStatus =
      activityStatus && excludeStatuses.includes(activityStatus.status);
    const isSpotEnabled = enableSpotCodes.includes(spotCode);
    const excludeService =
      code === "LATE_CHE" ||
      code === "LATE_DEP" ||
      code === "EARLY_ARR" ||
      code === "PETS" ||
      code === "AD_DOG_CL7" ||
      code === "MOBILITY" ||
      code === "AD_SN_200" ||
      code === "AD_SS_C56" ||
      code === "KLIM";

    return (
      isInExtraTypes &&
      !hasExcludedStatus &&
      !excludeService &&
      !service.hasAppliedBockedDays // remove services that have blocked days for the current reservation
    );
  });

  useEffect(() => {
    if (hRegularRef && hRegularRef.current) {
      setHasHRegularContent(hRegularRef.current.innerHTML.trim() !== "");
    }
  }, [hRegularRef]);

  return (
    <Tile hasHRegularContent={hasHRegularContent}>
      <ContentWrapper open={true}>
        {filteredServices.length > 0 ? (
          <>
            {extraTypes.includes("F&B") && (
              <InfoBoxWithMarginBottom>
                <P>{dict("contents.fBExtrasIntro", lang)} </P>
              </InfoBoxWithMarginBottom>
            )}
            {extraTypes.includes("Experience-on-request") && (
              <InfoBoxWithMarginBottom>
                <P>{dict("contents.activitiesExtrasIntro", lang)} </P>
              </InfoBoxWithMarginBottom>
            )}

            {filteredServices.map((service, index) => {
              const info = service.bookableUntilHoursBeforeArrival;
              let renderInfo;
              if (info) {
                if (info <= 24) {
                  renderInfo = (
                    <>
                      {dict("contents.beBooked", lang)}
                      {"\u00A0"}
                      {info}
                      {"\u00A0"}
                      {dict("contents.hours", lang)}
                      {"\u00A0"}
                      {dict("contents.hoursBefore", lang)}
                    </>
                  );
                } else if (info >= 24 && info % 24 === 0) {
                  renderInfo = (
                    <>
                      {dict("contents.beBooked", lang)}
                      {"\u00A0"}
                      {Math.ceil(info / 24)}
                      {"\u00A0"}
                      {dict("contents.days", lang)}
                      {"\u00A0"}
                      {dict("contents.daysBefore", lang)}
                    </>
                  );
                } else {
                  renderInfo = info;
                }
              }

              const disabled =
                service.bookableUntilHoursBeforeArrival &&
                now
                  .add(service.bookableUntilHoursBeforeArrival, "hour")
                  .isAfter(arrival);

              const getBookingInfo = (info, disabled) => {
                if (disabled) {
                  if (info < 24) {
                    return (
                      <>
                        {dict("contents.wasBooked", lang)}
                        {"\u00A0"}
                        {info}
                        {"\u00A0"}
                        {dict("contents.hours", lang)}
                        {"\u00A0"}
                        {dict("contents.hoursBefore", lang)}
                      </>
                    );
                  } else if (info > 24 && info % 24 === 0) {
                    return (
                      <>
                        {dict("contents.wasBooked", lang)}
                        {"\u00A0"}
                        {Math.ceil(info / 24)}
                        {"\u00A0"}
                        {dict("contents.days", lang)}
                        {"\u00A0"}
                        {dict("contents.daysBefore", lang)}
                      </>
                    );
                  } else if (info === 24) {
                    return (
                      <>
                        {dict("contents.wasBooked", lang)}
                        {"\u00A0"}
                        {info}
                        {"\u00A0"}
                        {dict("contents.hours", lang)}
                        {"\u00A0"}
                        {dict("contents.hoursBefore", lang)}
                      </>
                    );
                  }
                } else {
                  return renderInfo;
                }
              };

              return (
                <ExtrasCardWrapper key={index}>
                  <ExtrasCard
                    disabled={disabled}
                    title={service.name}
                    image={serviceImage(service.overviewImage?.images)}
                    price={`${calculateTotalPrice(
                      service.amount,
                      service.count,
                      service.quantity,
                      service.isQuantityRequired,
                      service.pricingUnit,
                      service.availabilityMode
                    )} ${priceType(service, lang)}`}
                    showIcon={!!info}
                    bookingInfo={getBookingInfo(info, disabled)}
                    service={service}
                    showSubTotal={true}
                    buttonProps={{
                      className: "green",
                      text: dict("contents.bookableExtraDetails", lang),
                      to: {
                        pathname: `/extra`,
                        search: `reservation=${reservationId}&serviceId=${
                          service.identifier
                        }&type=bookable&extraContentItem=${extraContentItem}&extraTypes=${encodeURIComponent(
                          extraTypes
                        )}`,
                      },
                    }}
                  />
                </ExtrasCardWrapper>
              );
            })}
          </>
        ) : (
          <P>
            {dict("contents.bookMoreExtras", lang)}{" "}
            <a
              href="mailto:hello@raus.life"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              hello@raus.life
            </a>
          </P>
        )}
      </ContentWrapper>
    </Tile>
  );
}

export default BookableExtras;

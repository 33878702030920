const getCompanionContent = (
  identifier,
  locationContent,
  unitGroupContent,
  cabinContent
) => {
  const checkByIdentifier = (content) => content.identifier === identifier;

  const contentInUnit = cabinContent?.find(checkByIdentifier);
  if (contentInUnit) return contentInUnit;

  const contentInUnitGroup = unitGroupContent?.find(checkByIdentifier);
  if (contentInUnitGroup) return contentInUnitGroup;

  const contentInLocation = locationContent?.find(checkByIdentifier);
  if (contentInLocation) return contentInLocation;

  return false;
};

export default getCompanionContent;

import ButtonLink from "atoms/ButtonLink";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ContentBox, InfoBox } from "../Contents/styles/Contents.styled";

function TravelInfo({ contents, showSauna, showHotTub }) {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  return (
    <ContentBox>
      {Object.keys(contents).map((key, index) => {
        if (
          (key === "sauna" && !showSauna) ||
          (key === "hottub" && !showHotTub)
        ) {
          return null;
        }
        return (
          <InfoBox key={index}>
            <ButtonLink
              caption={contents[key].title}
              to={"/" + key + "?reservation=" + reservationId}
            />
          </InfoBox>
        );
      })}
    </ContentBox>
  );
}

export default TravelInfo;

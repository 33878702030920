import arrowOutward from "assets/images/arrow_outward.svg";
import iconPurple from "assets/images/error-purple.svg";
import errorIcon from "assets/images/error.svg";
import house from "assets/images/illu-haus.svg";
import ExtraPageLayout from "layouts/ExtraPageLayout";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatPrice } from "utils/formatPrice";
import { priceType } from "utils/priceType";
import { serviceImage } from "utils/serviceImage";
import {
  AlignIcon,
  BoxWrapperNoP,
  CardPriceL,
  CardS,
  CardText,
  CenterContent,
  ContentBox,
  DatesBox,
  DatesWrapper,
  ExtraBox,
  ExtraLink,
  H,
  HS,
  HS2,
  ImageContainer,
  ImageWoRoundBorder,
  InfoBox,
  NoMarginP,
  NoteTitle,
  PWrapper,
  QuantityInfo,
  StyledSelect,
  SuccessInfoBox,
  SuccessInfoTitle,
  SuccessInfoWrapper,
  Text,
  ThemedCard,
  ThemedCardBox,
  ThemedCardInfo,
  ThemedCardTitle,
} from "../components/Contents/styles/Contents.styled";
import { LanguageContext } from "../provider/LanguageProvider";
import camelize from "../utils/camelize";
import dict from "../utils/translations";
import ExtraCheckout from "./ExtraCheckout";

function ExtraSubpage() {
  const { getReservation } = useAppContext();
  const { lang } = useContext(LanguageContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceId = searchParams.get("serviceId");
  const extraContentItem = searchParams.get("extraContentItem");
  const extraTypes = searchParams.get("extraTypes");
  const type = searchParams.get("type");
  // i know.. this is the same as URLSearchParams but as we are already using queryString in the project... and using everywhere but here...
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const reservation = getReservation(reservationId);
  const [preferredTime, setPreferredTime] = useState("none");

  const bookedService =
    type === "booked" && reservation && reservation.bookedServices
      ? reservation.bookedServices.find(
          (service) => service.identifier === serviceId
        )
      : null;

  const bookableService =
    type === "bookable" && reservation && reservation.bookableServices
      ? reservation.bookableServices.find(
          (service) => service.identifier === serviceId
        )
      : null;

  const requestedActivity =
    type === "requested" && reservation && reservation.bookableServices
      ? reservation.bookableServices.find(
          (service) =>
            service.identifier === serviceId &&
            service.activityStatus?.status === "requested"
        )
      : null;

  const notBookedActivity =
    type === "notBooked" && reservation && reservation.bookableServices
      ? reservation.bookableServices.find(
          (service) =>
            service.identifier === serviceId &&
            service.activityStatus?.status === "notBooked"
        )
      : null;

  const bookedActivity =
    type === "booked" && reservation && reservation.bookableServices
      ? reservation.bookableServices.find(
          (service) =>
            service.identifier === serviceId &&
            service.activityStatus?.status === "booked"
        )
      : null;

  let dateArrival;

  if (bookedActivity && bookedActivity.activityStatus) {
    const arrivalDate = bookedActivity.activityStatus.booked_date;

    if (arrivalDate) {
      const newArrivalDate = new Date(arrivalDate);
      let arrivalTime = newArrivalDate.toLocaleTimeString();
      arrivalTime = arrivalTime.substring(0, arrivalTime.length - 3);
      let day = newArrivalDate.getDate();
      let month = newArrivalDate.getMonth() + 1;
      if (String(day).length === 1) day = "0" + day;
      if (String(month).length === 1) month = "0" + month;

      dateArrival = day + "." + month + "." + newArrivalDate.getFullYear();
    }
  }

  const subTotal = useMemo(() => {
    if (!bookableService) return 0;

    let baseAmount = 0;
    if (
      bookableService.dates.length > 0 &&
      bookableService.dates[0].amount &&
      bookableService.dates[0].amount.grossAmount
    ) {
      baseAmount = parseFloat(bookableService.dates[0].amount.grossAmount);
    } else {
      baseAmount = parseFloat(bookableService.amount.grossAmount);
    }

    if (
      bookableService.availabilityMode === "Daily" &&
      bookableService.pricingUnit === "Person"
    ) {
      // Case 3
      return baseAmount / bookableService.count;
    } else if (
      bookableService.availabilityMode === "Arrival" &&
      bookableService.pricingUnit === "Person"
    ) {
      // Case 4
      return baseAmount / bookableService.count;
    } else {
      // Case 1 and other cases
      return baseAmount;
    }
  }, [bookableService]);

  const renderBookedDates = () => {
    if (bookableService.blockedDates.length <= 0) return null;
    const dateStrArray = bookableService.blockedDates.map((range) => {
      if (range.from !== range.to) {
        return `${range.from} - ${range.to}`;
      } else {
        return `${range.from}`;
      }
    });

    return (
      <ExtraBox>
        <NoteTitle>
          {dict("contents.exNoteTitle", lang)}: {dateStrArray.join(",")}
        </NoteTitle>
      </ExtraBox>
    );
  };

  const renderServiceLink = (service) => {
    if (
      service &&
      (service.code === "MEDITATION" || service.code === "VDS_AUDIO")
    ) {
      const linkStyle = {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      };

      if (service.code === "VDS_AUDIO" && lang === "de") {
        return (
          <a
            href="https://www.visitdarkskies.com/raus-erlebnis"
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
          >
            {dict("contents.vdsStart", lang)}
            <img src={arrowOutward} alt="Arrow" />
          </a>
        );
      } else if (service.code === "VDS_AUDIO" && lang === "en") {
        return (
          <a
            href="https://www.visitdarkskies.com/raus-experience"
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
          >
            {dict("contents.vdsStart", lang)}
            <img src={arrowOutward} alt="Arrow" />
          </a>
        );
      } else if (service.code === "MEDITATION" && lang === "de") {
        return (
          <a
            href="https://experience.raus.life/de/michaela-aue-x-raus"
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
          >
            {dict("contents.meditationStart", lang)}
            <img src={arrowOutward} alt="Arrow" />
          </a>
        );
      } else if (service.code === "MEDITATION" && lang === "en") {
        return (
          <a
            href="https://experience.raus.life/en/michaela-aue-x-raus"
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
          >
            {dict("contents.meditationStart", lang)}
            <img src={arrowOutward} alt="Arrow" />
          </a>
        );
      }
    }
    return null;
  };

  // const navigateToReservation = useMemo(() => {
  //   if (bookedService) return true
  //   if (
  //     bookableService &&
  //     ['booked', 'notBooked', 'requested'].includes(bookableService.activityStatus)
  //   )
  //     return true
  //   return false
  // }, [bookedService, bookableService])

  return (
    <div>
      <ExtraPageLayout
        isHeaderSubPageVisible={true}
        subTitle={
          bookableService?.code === "PETS" ||
          bookedService?.code === "PETS" ||
          bookableService?.code === "AD_DOG_CL7" ||
          bookedService?.code === "AD_DOG_CL7" ||
          bookableService?.code === "MOBILITY" ||
          bookedService?.code === "MOBILITY" ||
          bookableService?.code === "AD_SN_200" ||
          bookedService?.code === "AD_SN_200" ||
          bookableService?.code === "AD_SS_C56" ||
          bookedService?.code === "AD_SS_C56" ||
          bookedService?.code === "KLIM"
            ? dict("contentItems.beforeTrip.title", lang)
            : dict(`extraContentItems.${extraContentItem}.title`, lang)
        }
        title={
          bookedService?.name ||
          bookableService?.name ||
          requestedActivity?.name ||
          bookedActivity?.name ||
          notBookedActivity?.name ||
          dict("contents.waitExtrasLoading", lang)
        }
        overrideBackLink={`/reservation?reservation=${reservationId}`}
      >
        {!!bookedService && (
          <ContentBox>
            <ImageContainer>
              <img
                src={serviceImage(bookedService.overviewImage?.images)}
                alt={bookedService.name}
              />
            </ImageContainer>
            {!(
              bookedService.code === "MEDITATION" ||
              bookedService.code === "VDS_AUDIO"
            ) && (
              <SuccessInfoBox>
                <SuccessInfoWrapper>
                  <SuccessInfoTitle>
                    {" "}
                    {dict("contents.extraBookedF", lang)}{" "}
                  </SuccessInfoTitle>
                  <NoMarginP
                    color="#fff"
                    dangerouslySetInnerHTML={{
                      __html: dict(
                        "extraContentItems.activity.confirmedText",
                        lang
                      ),
                    }}
                  />
                </SuccessInfoWrapper>
              </SuccessInfoBox>
            )}
            {renderServiceLink(bookedService) && (
              <ExtraLink>{renderServiceLink(bookedService)}</ExtraLink>
            )}
            {/* <ExtraBox>
            <H>{dict('contents.exPrice', lang)}</H>
            <CardPriceL>
              {subTotal} {priceType(bookedService, lang)}
            </CardPriceL>
          </ExtraBox> */}
            <ExtraBox>
              <H>{dict("contents.exQty", lang)}</H>
              {bookedService.dates && (
                <>
                  {bookedService.dates.map((dateObject, index) => (
                    <div key={index}></div>
                  ))}
                  <div>
                    {" "}
                    {bookedService.dates.reduce(
                      (sum, dateObject) => sum + dateObject.count,
                      0
                    )}{" "}
                    x {bookedService.name}
                  </div>
                </>
              )}
            </ExtraBox>
            <ExtraBox>
              <H>{dict("contents.totalPrice", lang)}</H>
              <CardPriceL>
                {formatPrice(bookedService.amount.grossAmount)}€{" "}
              </CardPriceL>
            </ExtraBox>
            <HS>{dict("contents.exDescription", lang)}</HS>
            <Text
              dangerouslySetInnerHTML={{ __html: bookedService.description }}
            />
          </ContentBox>
        )}
        {!!bookableService && (
          <div>
            <ImageContainer>
              <img
                src={serviceImage(bookableService.overviewImage?.images)}
                alt={bookableService.name}
              />
            </ImageContainer>
            <ExtraBox>
              <H>{dict("contents.exPrice", lang)}</H>
              <CardPriceL>
                {bookableService.amount.grossAmount === 0
                  ? dict("contents.freeExtra", lang)
                  : `${formatPrice(subTotal)}€ ${priceType(
                      bookableService,
                      lang
                    )}`}

                {bookableService?.quantityInfo !== "" &&
                  bookableService?.quantityInfo !== "Keine" && (
                    <QuantityInfo>
                      {dict(
                        `quantityInfo.${camelize(
                          bookableService.quantityInfo
                        )}`,
                        lang
                      )}
                    </QuantityInfo>
                  )}
              </CardPriceL>
            </ExtraBox>
            <ExtraBox>
              <HS>{dict("contents.exDescription", lang)}</HS>
              <Text
                dangerouslySetInnerHTML={{
                  __html: bookableService.description,
                }}
              />
            </ExtraBox>
            <ExtraBox>
              {bookableService.activityInfo?.date_text ||
              bookableService.activityInfo?.duration ? (
                <BoxWrapperNoP>
                  <DatesWrapper>
                    <DatesBox>
                      {bookableService.activityInfo?.date_text && (
                        <>
                          <HS2>
                            {dict(
                              "extraContentItems.activity.activityDate",
                              lang
                            )}
                          </HS2>
                          <PWrapper>
                            <Text>
                              {bookableService.activityInfo.date_text}
                            </Text>
                          </PWrapper>
                        </>
                      )}
                    </DatesBox>
                    <DatesBox>
                      {bookableService.type === "Experience-on-request" && (
                        <>
                          <HS2>
                            {dict(
                              "extraContentItems.activity.preferredActivityTime",
                              lang
                            )}
                          </HS2>
                          <PWrapper>
                            <Text>
                              <StyledSelect
                                defaultValue={"none"}
                                onChange={(e) =>
                                  setPreferredTime(e.target.value)
                                }
                                className={
                                  preferredTime === "none" ? "invalid" : "valid"
                                }
                              >
                                <option value="none">
                                  {dict(
                                    "extraContentItems.activity.preferredActivityTimeChoose",
                                    lang
                                  )}
                                </option>
                                <option value="Flexibel">
                                  {dict(
                                    "extraContentItems.activity.preferredActivityTimeFlexible",
                                    lang
                                  )}
                                </option>
                                <option value="Vormittag">
                                  {dict(
                                    "extraContentItems.activity.preferredActivityTimeAM",
                                    lang
                                  )}
                                </option>
                                <option value="Nachmittag">
                                  {dict(
                                    "extraContentItems.activity.preferredActivityTimePM",
                                    lang
                                  )}
                                </option>
                              </StyledSelect>
                            </Text>
                          </PWrapper>
                        </>
                      )}
                    </DatesBox>
                  </DatesWrapper>
                </BoxWrapperNoP>
              ) : null}

              {bookableService.activityInfo?.time_text ||
              bookableService.activityInfo?.participants ? (
                <BoxWrapperNoP>
                  <DatesWrapper>
                    <DatesBox>
                      {bookableService.activityInfo?.duration && (
                        <>
                          <HS2>
                            {dict(
                              "extraContentItems.activity.activityDuration",
                              lang
                            )}
                          </HS2>
                          <PWrapper>
                            <Text>{bookableService.activityInfo.duration}</Text>
                          </PWrapper>
                        </>
                      )}
                    </DatesBox>
                    <DatesBox>
                      {bookableService.activityInfo?.participants && (
                        <>
                          <HS2>
                            {dict(
                              "extraContentItems.activity.activityParticipants",
                              lang
                            )}
                          </HS2>
                          <PWrapper>
                            <Text>
                              {bookableService.activityInfo.participants}
                            </Text>
                          </PWrapper>
                        </>
                      )}
                    </DatesBox>
                  </DatesWrapper>
                </BoxWrapperNoP>
              ) : null}

              {bookableService.activityInfo?.good_to_know && (
                <>
                  <HS>
                    {dict("extraContentItems.activity.activityGTK", lang)}
                  </HS>
                  <PWrapper noMargin>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: bookableService.activityInfo.good_to_know,
                      }}
                    />
                  </PWrapper>
                </>
              )}

              {bookableService.activityInfo?.distance_from_cabin && (
                <>
                  <HS>
                    {dict("extraContentItems.activity.activityDistance", lang)}
                  </HS>
                  <InfoBox></InfoBox>
                  <PWrapper>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html:
                          bookableService.activityInfo.distance_from_cabin,
                      }}
                    />
                  </PWrapper>
                </>
              )}

              {bookableService.activityInfo?.please_note && (
                <>
                  <HS>
                    {dict("extraContentItems.activity.activityNote", lang)}
                  </HS>
                  <PWrapper>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: bookableService.activityInfo.please_note,
                      }}
                    />
                  </PWrapper>
                </>
              )}

              {bookableService.activityInfo?.about_the_host && (
                <>
                  <HS>
                    {dict("extraContentItems.activity.activityHost", lang)}
                  </HS>
                  <PWrapper>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: bookableService.activityInfo.about_the_host,
                      }}
                    />
                  </PWrapper>
                </>
              )}
            </ExtraBox>
            {renderBookedDates()}
            <CardS>
              <ExtraCheckout
                extraContentItem={extraContentItem}
                extraTypes={extraTypes}
                preferredTime={preferredTime}
              />
            </CardS>
          </div>
        )}
        {!!requestedActivity && (
          <ContentBox>
            <ImageContainer>
              <img
                src={serviceImage(requestedActivity.overviewImage?.images)}
                alt={requestedActivity.name}
              />
            </ImageContainer>
            <ThemedCardBox>
              <ThemedCard theme="orange">
                <CardText>
                  <ThemedCardInfo>
                    <AlignIcon noPadding>
                      <img src={iconPurple} alt="icon" />
                      <div>
                        <ThemedCardTitle theme="orange">
                          {dict(
                            "extraContentItems.activity.pendingTitle",
                            lang
                          )}
                        </ThemedCardTitle>
                        <NoMarginP
                          dangerouslySetInnerHTML={{
                            __html: dict(
                              "extraContentItems.activity.pendingText",
                              lang
                            ),
                          }}
                        />
                      </div>
                    </AlignIcon>
                  </ThemedCardInfo>
                </CardText>
              </ThemedCard>
            </ThemedCardBox>
            <HS>{dict("contents.exDescription", lang)}</HS>
            <Text
              dangerouslySetInnerHTML={{
                __html: requestedActivity.description,
              }}
            />
            {requestedActivity.activityInfo?.date_text ||
            requestedActivity.activityInfo?.duration ? (
              <BoxWrapperNoP>
                {requestedActivity.activityInfo?.date_text && (
                  <>
                    <Text italic>
                      {dict("extraContentItems.activity.dateTimeTbc", lang)}
                    </Text>
                    <PWrapper></PWrapper>
                  </>
                )}
              </BoxWrapperNoP>
            ) : null}
            {requestedActivity.activityInfo?.time_text ||
            requestedActivity.activityInfo?.participants ? (
              <BoxWrapperNoP>
                <DatesWrapper>
                  <DatesBox>
                    {requestedActivity.activityInfo?.duration && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityDuration",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>{requestedActivity.activityInfo.duration}</Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                  <DatesBox>
                    {requestedActivity.activityInfo?.participants && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityParticipants",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>
                            {requestedActivity.activityInfo.participants}
                          </Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                </DatesWrapper>
              </BoxWrapperNoP>
            ) : null}

            {requestedActivity.activityInfo?.good_to_know && (
              <>
                <HS>{dict("extraContentItems.activity.activityGTK", lang)}</HS>
                <PWrapper noMargin>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: requestedActivity.activityInfo.good_to_know,
                    }}
                  />
                </PWrapper>
              </>
            )}

            {requestedActivity.activityInfo?.distance_from_cabin && (
              <>
                <HS>
                  {dict("extraContentItems.activity.activityDistance", lang)}
                </HS>
                <InfoBox></InfoBox>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html:
                        requestedActivity.activityInfo.distance_from_cabin,
                    }}
                  />
                </PWrapper>
              </>
            )}

            {requestedActivity.activityInfo?.please_note && (
              <>
                <HS>{dict("extraContentItems.activity.activityNote", lang)}</HS>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: requestedActivity.activityInfo.please_note,
                    }}
                  />
                </PWrapper>
              </>
            )}

            {requestedActivity.activityInfo?.about_the_host && (
              <>
                <HS>{dict("extraContentItems.activity.activityHost", lang)}</HS>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: requestedActivity.activityInfo.about_the_host,
                    }}
                  />
                </PWrapper>
              </>
            )}
          </ContentBox>
        )}
        {!!bookedActivity && (
          <ContentBox>
            <ImageContainer>
              <img
                src={serviceImage(bookedActivity.overviewImage?.images)}
                alt={bookedActivity.name}
              />
            </ImageContainer>
            <InfoBox>
              <SuccessInfoBox>
                <SuccessInfoWrapper>
                  <SuccessInfoTitle>
                    {" "}
                    {dict("contents.extraBookedF", lang)}{" "}
                  </SuccessInfoTitle>
                  <NoMarginP
                    color="#fff"
                    dangerouslySetInnerHTML={{
                      __html: dict(
                        "extraContentItems.activity.confirmedText",
                        lang
                      ),
                    }}
                  />
                </SuccessInfoWrapper>
              </SuccessInfoBox>
            </InfoBox>
            <H>{dict("contents.totalPrice", lang)}</H>
            <CardPriceL>
              {formatPrice(bookedActivity.amount.grossAmount)}€{" "}
            </CardPriceL>
            <HS>{dict("contents.exDescription", lang)}</HS>
            <Text
              dangerouslySetInnerHTML={{ __html: bookedActivity.description }}
            />

            {bookedActivity.activityInfo?.date_text ||
            bookedActivity.activityInfo?.duration ? (
              <BoxWrapperNoP>
                <DatesWrapper>
                  <DatesBox>
                    {bookedActivity.activityInfo?.date_text && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityDate",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>{dateArrival}</Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                  <DatesBox>
                    {bookedActivity.activityInfo?.time_text && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityTime",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>
                            {bookedActivity.activityStatus.booked_time}{" "}
                            {dict("contents.arrivalClock", lang)}
                          </Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                </DatesWrapper>
              </BoxWrapperNoP>
            ) : null}

            {bookedActivity.activityInfo?.time_text ||
            bookedActivity.activityInfo?.participants ? (
              <BoxWrapperNoP>
                <DatesWrapper>
                  <DatesBox>
                    {bookedActivity.activityInfo?.duration && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityDuration",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>{bookedActivity.activityInfo.duration}</Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                  <DatesBox>
                    {bookedActivity.activityInfo?.participants && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityParticipants",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text>
                            {bookedActivity.activityInfo.participants}
                          </Text>
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                </DatesWrapper>
              </BoxWrapperNoP>
            ) : null}
            {bookedActivity.activityInfo?.meeting_point ||
            bookedActivity.partner ? (
              <BoxWrapperNoP>
                <DatesWrapper>
                  <DatesBox>
                    {bookedActivity.activityInfo?.meeting_point && (
                      <>
                        <HS>
                          {dict(
                            "extraContentItems.activity.activityMeeting",
                            lang
                          )}
                        </HS>
                        <PWrapper>
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: bookedActivity.activityInfo.meeting_point,
                            }}
                          />
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                  <DatesBox>
                    {bookedActivity.partner && (
                      <>
                        <HS2>
                          {dict(
                            "extraContentItems.activity.activityContact",
                            lang
                          )}
                        </HS2>
                        <PWrapper>
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: bookedActivity.partner.contact_name,
                            }}
                          />
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: bookedActivity.partner.email,
                            }}
                          />
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: bookedActivity.partner.phone,
                            }}
                          />
                        </PWrapper>
                      </>
                    )}
                  </DatesBox>
                </DatesWrapper>
              </BoxWrapperNoP>
            ) : null}

            {bookedActivity.activityInfo?.about_the_host && (
              <>
                <HS>{dict("extraContentItems.activity.activityHost", lang)}</HS>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: bookedActivity.activityInfo.about_the_host,
                    }}
                  />
                </PWrapper>
              </>
            )}

            {bookedActivity.activityInfo?.good_to_know && (
              <>
                <HS>{dict("extraContentItems.activity.activityGTK", lang)}</HS>
                <PWrapper noMargin>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: bookedActivity.activityInfo.good_to_know,
                    }}
                  />
                </PWrapper>
              </>
            )}
            {bookedActivity.activityInfo?.distance_from_cabin && (
              <>
                <HS>
                  {dict("extraContentItems.activity.activityDistance", lang)}
                </HS>
                <InfoBox></InfoBox>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: bookedActivity.activityInfo.distance_from_cabin,
                    }}
                  />
                </PWrapper>
              </>
            )}

            {bookedActivity.activityInfo?.please_note && (
              <>
                <HS>{dict("extraContentItems.activity.activityNote", lang)}</HS>
                <PWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: bookedActivity.activityInfo.please_note,
                    }}
                  />
                </PWrapper>
              </>
            )}
          </ContentBox>
        )}
        {!!notBookedActivity && (
          <ContentBox>
            <ImageContainer>
              <img
                src={serviceImage(notBookedActivity.overviewImage?.images)}
                alt={notBookedActivity.name}
              />
            </ImageContainer>
            <ThemedCardBox>
              <ThemedCard theme="red">
                <CardText>
                  <ThemedCardInfo>
                    <AlignIcon noPadding>
                      <img src={errorIcon} alt="icon" />
                      <div>
                        <ThemedCardTitle theme="red">
                          {dict("extraContentItems.activity.deniedTitle", lang)}
                        </ThemedCardTitle>
                        <NoMarginP
                          dangerouslySetInnerHTML={{
                            __html: dict(
                              "extraContentItems.activity.deniedText",
                              lang
                            ),
                          }}
                        />
                      </div>
                    </AlignIcon>
                  </ThemedCardInfo>
                </CardText>
              </ThemedCard>
            </ThemedCardBox>
            <HS>{dict("contents.exDescription", lang)}</HS>
            <Text
              dangerouslySetInnerHTML={{
                __html: notBookedActivity.description,
              }}
            />
          </ContentBox>
        )}
        {!bookableService &&
          !bookedService &&
          !requestedActivity &&
          !bookedActivity &&
          !notBookedActivity && (
            <CenterContent>
              <ImageWoRoundBorder src={house} alt="house" />
              <br />
            </CenterContent>
          )}
      </ExtraPageLayout>
    </div>
  );
}

export default ExtraSubpage;

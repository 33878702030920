import image from "assets/images/illu-no-extra.svg";

export const serviceImage = (images) => {
  if (images?.medium) return images.medium;
  if (images?.small) return images.small;
  if (images?.xsmall) return images.xsmall;
  if (images?.xxsmall) return images.xxsmall;
  if (images?.xxxsmall) return images.xxxsmall;
  return image;
};

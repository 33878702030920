import successIcon from "assets/images/check_circle.svg";
import errorIcon from "assets/images/error.svg";
import infoIcon from "assets/images/info.svg";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useUpdateStayLongerData } from "hooks/useUpdateStayLongerData";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import stayLongerService from "services/stayLonger.service";
import styled from "styled-components";
import theme from "../pages/styles/Theme";
import { pSmall } from "../pages/styles/Typography";
import { LanguageContext } from "../provider/LanguageProvider";
import { getDefaultDateFormat } from "../utils/getDefaultDateFormat";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  ErrorMessage,
  MarginP,
  NoMarginP,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  UnderlinedP,
  UnderlinedPCentered,
} from "./Contents/styles/Contents.styled";
import { Button } from "./styles/Button.styled";
import { Warning } from "./MessageBoxes";
dayjs.extend(LocalizedFormat);

const StayLongerExplainer = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.25rem;
  ${pSmall};
  font-style: italic;
  text-align: center;
  color: ${theme.colors.grayDark};
`;

function StayLongerCard({ daysBeforeArrival }) {
  const { lang } = useContext(LanguageContext);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const { getReservation } = useAppContext();
  const reservation = getReservation(reservationId);
  const singleNightPrice =
    reservation?.stayLonger?.singleNightPrice?.priceInEuro;
  const stayLongerStatus = reservation?.stayLonger?.stayLongerStatus;
  const [buttonClicked, setButtonClicked] = useState(false); // for disabling button
  const [attemptedBooking, setAttemptedBooking] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hideCard, setHideCard] = useState(
    localStorage.getItem("hideStayLongerCard")
  );

  const [currentReservationId, setCurrentReservationId] = useState(null);
  useUpdateStayLongerData(currentReservationId);

  const handleSubmit = async () => {
    setButtonClicked(true);
    setAttemptedBooking(true);

    let result = await stayLongerService.requestToStayLonger(reservationId);
    if (result) {
      // wait... for the HS ticket to be created and the `getStayLongerData` receives an updated status
      await new Promise((r) => setTimeout(r, 3000));
      // this fires `getStayLongerData in useUpdateStayLongerData`
      setCurrentReservationId(reservationId);
      // todo change the state of the card
    } else {
      setButtonClicked(false);
    }
  };

  const handleRemindMeLater = () => {
    setHideCard(true);
    localStorage.setItem("hideStayLongerCard", "true");
  };

  const missingData = !reservation || !stayLongerStatus || !singleNightPrice;
  const hasUpsellPotential =
    stayLongerStatus?.possibleAfter === "Ja" ||
    stayLongerStatus?.possibleBefore === "Ja";
  const arrivalIsFarInTheFuture = daysBeforeArrival >= 8;

  const offerUpsell =
    !hideCard && !missingData && hasUpsellPotential && !arrivalIsFarInTheFuture;
  // console.log("IN STAY LONGER -------------");
  // console.log("  >> daysBeforeArrival", daysBeforeArrival);
  // console.log("  >> singleNightPrice", singleNightPrice);
  // console.log("  >> stayLongerStatus", stayLongerStatus);
  // console.log("  >> missingData", missingData);
  // console.log("  >> hasUpsellPotential", hasUpsellPotential);
  // console.log("  >> arrivalIsFarInTheFuture", arrivalIsFarInTheFuture);
  // console.log("  >> hasUpsellPotential", hasUpsellPotential);
  // console.log("  >> offerUpsell", offerUpsell);

  if (!offerUpsell) {
    return null;
  }
  return (
    <ThemedCard theme={getThemeByStatus(stayLongerStatus.status)}>
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding={true}>
            <StatusIcon status={stayLongerStatus.status} />
            <div>
              <ThemedCardTitle
                theme={getThemeByStatus(stayLongerStatus.status)}
              >
                {dict("stayLonger.title", lang)}
              </ThemedCardTitle>
              <Message
                singleNightPrice={singleNightPrice}
                hasUpsellPotential={hasUpsellPotential}
                status={stayLongerStatus.status}
              />
            </div>
          </AlignIcon>
        </ThemedCardInfo>
        <div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          {stayLongerStatus.status === "NoRequestExisting" && (
            <Form
              stayLongerStatus={stayLongerStatus}
              reservation={reservation}
              handleSubmit={handleSubmit}
              buttonClicked={buttonClicked}
              attemptedBooking={attemptedBooking}
              handleRemindMeLater={handleRemindMeLater}
            />
          )}

          {daysBeforeArrival >= 10 && (
            <UnderlinedPCentered onClick={handleRemindMeLater}>
              {dict("stayLonger.remindLater", lang)}
            </UnderlinedPCentered>
          )}
        </div>
      </CardText>
    </ThemedCard>
  );
}

const StatusIcon = ({ status }) => {
  if (status === "NoRequestExisting") return null;
  return (
    <img
      src={
        status === "CompletedNotPossible"
          ? errorIcon
          : status === "CompletedExtended"
          ? successIcon
          : infoIcon
      }
      alt="icon"
    />
  );
};

const Message = ({ status, hasUpsellPotential, singleNightPrice }) => {
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const { lang } = useContext(LanguageContext);

  return (
    <>
      {status === "NoRequestExisting" && hasUpsellPotential && (
        <>
          <MarginP
            dangerouslySetInnerHTML={{
              __html: dict("stayLonger.textPossible", lang),
            }}
          />
          <MarginP
            dangerouslySetInnerHTML={{
              __html: dict("stayLonger.subtextPossible", lang, [
                singleNightPrice,
              ]),
            }}
          />
          <UnderlinedP
            onClick={() => setShowPaymentDetails(!showPaymentDetails)}
          >
            {dict("contents.exPriceFinal", lang)}
          </UnderlinedP>
          {showPaymentDetails && (
            <MarginP
              dangerouslySetInnerHTML={{
                __html: dict("stayLonger.paymentSubtextPossible", lang),
              }}
            />
          )}
        </>
      )}
      {(status === "Not Started" || status === "In Bearbeitung") && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textInProgress", lang),
          }}
        />
      )}
      {status === "Erledigt" && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textCompletedExtended", lang),
          }}
        />
      )}
      {/* {status === "CompletedNotPossible" && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textCompletedNotPossible", lang),
          }}
        />
      )} */}
    </>
  );
};

const Form = ({
  reservation,
  stayLongerStatus,
  buttonClicked,
  attemptedBooking,
  handleSubmit,
}) => {
  const { lang } = useContext(LanguageContext);

  const oneDayBefore = dayjs(reservation.arrival).subtract(1, "day");
  const oneDayAfter = dayjs(reservation.departure).add(1, "day");

  if (
    stayLongerStatus?.possibleAfter === "Ja" &&
    stayLongerStatus?.possibleBefore !== "Ja"
  ) {
    return (
      <>
        <Button
          className="primary"
          onClick={handleSubmit}
          $loading={buttonClicked}
        >
          {buttonClicked && attemptedBooking
            ? dict("contents.waitRequestLoading", lang)
            : buttonClicked && !attemptedBooking
            ? dict("contents.reqestSent", lang)
            : dict("stayLonger.optionAfter", lang)}
        </Button>
        <StayLongerExplainer>
          {dict("stayLonger.optionAfterExplainer", lang, [
            getDefaultDateFormat({
              date: oneDayAfter,
              locale: lang,
              withTime: true,
            }),
          ])}
        </StayLongerExplainer>
      </>
    );
  }

  if (
    stayLongerStatus?.possibleBefore === "Ja" &&
    stayLongerStatus?.possibleAfter !== "Ja"
  ) {
    return (
      <>
        <Button
          className="primary"
          onClick={handleSubmit}
          $loading={buttonClicked}
        >
          {buttonClicked
            ? dict("contents.waitRequestLoading", lang)
            : dict("stayLonger.optionBefore", lang)}
        </Button>
        <StayLongerExplainer>
          {dict("stayLonger.optionBeforeExplainer", lang, [
            getDefaultDateFormat({
              date: oneDayBefore,
              locale: lang,
              withTime: true,
            }),
          ])}
        </StayLongerExplainer>
      </>
    );
  }

  // if (stayLongerStatus.possibleBefore && stayLongerStatus.possibleAfter) {
  //   return (
  //     <>
  //       <RadioBox>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionBefore"
  //             value="before"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionBefore" className="radio-label">
  //             {dict('stayLonger.optionBefore', lang)}
  //           </StyledLabel>
  //         </div>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionAfter"
  //             value="after"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionAfter" className="radio-label">
  //             {dict('stayLonger.optionAfter', lang)}
  //           </StyledLabel>
  //         </div>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionBoth"
  //             value="both"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionBoth" className="radio-label">
  //             {dict('stayLonger.optionBoth', lang)}
  //           </StyledLabel>
  //         </div>
  //       </RadioBox>
  //       <Button className="primary" onClick={handleSubmit} $loading={buttonClicked}>
  //         {buttonClicked
  //           ? dict('contents.waitRequestLoading', lang)
  //           : dict('stayLonger.submitButton', lang)}
  //       </Button>
  //     </>
  //   )
  // }
};

const getThemeByStatus = (status) => {
  switch (status) {
    case "NoRequestExisting":
    case "In Bearbeitung":
      return "blue";
    case "Erledigt":
      return "green";
    default:
      return "blue";
  }
};

export default StayLongerCard;

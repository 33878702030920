import * as Sentry from "@sentry/browser";
import { createContext, useCallback, useContext, useReducer } from "react";
import { LocalStorage } from "utils/localStorage";
import {
  appReducer,
  setCabin,
  setExpanded,
  setLocation,
  setProperty,
  setReservation,
  setUnit,
  setUnitGroup,
} from "../hooks/useAppReducer";

export const AppContext = createContext({});

const initialStates = {
  user: LocalStorage.getItem("user"),
  booking: LocalStorage.getItem("booking"),
  reservation: LocalStorage.getItem("reservation"),
  property: LocalStorage.getItem("property"),
  propertyService: LocalStorage.getItem("property_service"),
  locationContent: LocalStorage.getItem("location_content"),
  unit: LocalStorage.getItem("unit"),
  cabinContent: LocalStorage.getItem("unit_content"),
  unitGroup: LocalStorage.getItem("unit-group"),
  unitGroupContent: LocalStorage.getItem("unitgroup_content"),
  cabin: LocalStorage.getItem("cabin"),
  cabinManual: LocalStorage.getItem("cabinManual"),
  location: LocalStorage.getItem("location"),
  /** The user has completed the check-in form. This doesn't mean they're already in the cabin */
  hasCheckedIn: LocalStorage.getItem("hasCheckedIn"),
};

export function useAppContext() {
  return useContext(AppContext);
}

export function useAppState() {
  return useContext(AppContext).state;
}

const useLocalStorage = (state, dispatch) => {
  // todo: remove these getters later
  const getCabin = useCallback(
    (reservation) => {
      Sentry.setContext("appState", {
        givenReservation: reservation,
        cabinInState: state.cabin,
      });

      if (!reservation?.unit) return false;
      const unitKey = reservation.unit.replace("/api/units/", "");
      const cabin = state.cabin?.[unitKey];
      if (cabin) return cabin;

      const legacyCabin = LocalStorage.getItem(`cabin_${unitKey}`);
      if (legacyCabin) {
        dispatch(setCabin(legacyCabin, unitKey));
        LocalStorage.removeItem(unitKey);

        return legacyCabin;
      }
    },
    [state, dispatch]
  );

  const getExpanded = useCallback(
    (reservationId, expandable) => {
      const expanded = state.expanded?.[reservationId]?.[expandable];
      if (typeof expanded === "boolean") return expanded;

      const expansionKey = `expanded-${expandable}-${reservationId}`;
      const legacyExpanded = LocalStorage.getItem(expansionKey);
      if (typeof legacyExpanded === "boolean") {
        dispatch(setExpanded(reservationId, expandable, legacyExpanded));
        LocalStorage.removeItem(expansionKey);

        return legacyExpanded;
      }
    },
    [state, dispatch]
  );

  const getReservation = useCallback(
    (reservationId) => {
      Sentry.setContext("appState", {
        givenReservationId: reservationId,
        reservationInState: state.reservation,
      });

      // console.log("state.reservation", state.reservation);
      // console.log(
      //   "forceError for debug",
      //   LocalStorage.getItem("booking")[1].test124
      // );

      // console.log("state in getReservation", state);
      // console.log("reservationId in getReservation", reservationId);
      const reservation = state.reservation?.[reservationId];
      // console.log("reservation in getReservation", reservation);
      if (reservation) return reservation;

      // we either did not have a reservationId parameter or, for whatever reason, the requested res does not exist in state
      // get the first one... not ideal as we should check if this booking has more than one res and then redirect
      // to the "puck res screen" but i dont know how to do this from here
      if (state.reservation && Object.keys(state.reservation).length > 0) {
        // console.log("get and return first res");
        return Object.values(state.reservation)[0];
      }

      const legacyReservationKey = `reservation_${reservationId}`;
      const legacyReservation = LocalStorage.getItem(legacyReservationKey);
      if (legacyReservation) {
        dispatch(setReservation(legacyReservation, reservationId));
        LocalStorage.removeItem(legacyReservationKey);

        return legacyReservation;
      }
    },
    [state, dispatch]
  );

  const getLocation = useCallback(
    (spotCode) => {
      Sentry.setContext("appState", {
        givenSpotCode: spotCode,
        locationInState: state.location,
      });

      const location = state.location?.[spotCode];
      if (location) return location;

      const locationKey = `location_${spotCode}`;
      const legacyLocation = LocalStorage.getItem(locationKey);
      if (legacyLocation) {
        dispatch(setLocation(legacyLocation, spotCode));
        LocalStorage.removeItem(spotCode);

        return legacyLocation;
      }
    },
    [state, dispatch]
  );

  const getProperty = useCallback(
    (reservation) => {
      // console.log("getProperty", reservation);

      Sentry.addBreadcrumb({
        message: "Reservation " + JSON.stringify(reservation),
        level: "info",
      });

      Sentry.setContext("appState", {
        givenReservation: reservation,
        propertyInState: state.property,
      });

      if (!reservation?.property) return false;

      const propertyId = reservation.property?.replace("/api/properties/", "");
      const property = state.property?.[propertyId];
      if (property) return property;

      const propertyKey = `property_${propertyId}`;
      const legacyProperty = LocalStorage.getItem(propertyKey);
      if (legacyProperty) {
        dispatch(setProperty(legacyProperty, propertyId));
        LocalStorage.removeItem(propertyKey);

        return legacyProperty;
      }
    },
    [state, dispatch]
  );

  const getUnit = useCallback(
    (reservation) => {
      Sentry.setContext("appState", {
        givenReservation: reservation,
        unitInState: state.unit,
      });

      if (!reservation?.unit) return false;
      const unitId = reservation.unit.replace("/api/units/", "");
      const unit = state.unit?.[unitId];
      if (unit) return unit;

      const unitKey = `unit_${unitId}`;
      const legacyUnit = LocalStorage.getItem(unitKey);
      if (legacyUnit) {
        dispatch(setUnit(legacyUnit, unitId));
        LocalStorage.removeItem(unitKey);

        return legacyUnit;
      }
    },
    [state, dispatch]
  );

  const getUnitGroup = useCallback(
    (reservation) => {
      Sentry.setContext("appState", {
        givenReservation: reservation,
        unitGroupInState: state.unitGroup,
      });

      if (!reservation?.unitGroup) return false;

      const unitGroupId = reservation.unitGroup.replace(
        "/api/unit-groups/",
        ""
      );
      const unitGroup = state.unitGroup?.[unitGroupId];
      if (unitGroup) return unitGroup;

      const unitGroupKey = `unit-group_${unitGroupId}`;
      const legacyUnitGroup = LocalStorage.getItem(unitGroupKey);
      if (legacyUnitGroup) {
        dispatch(setUnitGroup(legacyUnitGroup, unitGroupId));
        LocalStorage.removeItem(unitGroupKey);

        return legacyUnitGroup;
      }
    },
    [state, dispatch]
  );

  return {
    state,
    dispatch,
    getCabin,
    getExpanded,
    getProperty,
    getReservation,
    getUnit,
    getUnitGroup,
    getLocation,
  };
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialStates);
  const value = useLocalStorage(state, dispatch);

  Sentry.setContext("appState", {
    user: LocalStorage.getItem("user"),
    booking: LocalStorage.getItem("booking"),
    reservation: LocalStorage.getItem("reservation"),
    property: LocalStorage.getItem("property"),
    propertyService: LocalStorage.getItem("property_service"),
    unit: LocalStorage.getItem("unit"),
    unitGroup: LocalStorage.getItem("unit-group"),
    cabin: LocalStorage.getItem("cabin"),
    location: LocalStorage.getItem("location"),
    hasCheckedIn: LocalStorage.getItem("hasCheckedIn"),
  });

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

import { useSearchParams } from "react-router-dom";
import { searchParamsToObject } from "../utils/searchParamsToObject";

export function useNavigationPath() {
  const [searchParams] = useSearchParams();
  const searchParamsObj = searchParamsToObject(searchParams);

  const trackParamKeys = [
    "utm",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  const trackingParamsObj = Object.entries(searchParamsObj)
    .filter(([key, value]) => {
      return trackParamKeys.includes(key);
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return buildPathFunc(trackingParamsObj);
}

const buildPathFunc =
  (trackingParams) =>
  (path, searchParams = {}) => {
    const urlSearchParams = new URLSearchParams({
      ...trackingParams,
      ...searchParams,
    });
    if (!searchParams) return path;
    return `${path}?${urlSearchParams.toString()}`;
  };

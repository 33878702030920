import house from "assets/images/illu-haus.svg";
import logo from "assets/images/logo.svg";
import { H } from "components/Contents/styles/Contents.styled";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { checkInUser, setUser } from "hooks/useAppReducer";
import { useGetBookingData } from "hooks/useGetBookingData";
import { usePostHog } from "posthog-js/react";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "services/auth.service";
import { pushToHubSpot } from "utils/gtm";
import dict from "utils/translations";
import CheckinService from "../services/checkin.service";
import { getCompletePathFromURL } from "../utils/getCompletePathFromURL";
import {
  Button,
  ButtonContainer,
  DataInput,
  Error,
  FormWrapper,
  Image,
  InputContainer,
  LoginBox,
  LoginIntro,
  LoginTitle,
  LoginWrapper,
  Logo,
} from "./styles/Login.styled";
import { useStayStatus } from "../hooks/useStayStatus";
import { useNavigationPath } from "../hooks/useNavigationPath";

dayjs.extend(isSameOrBefore);

function Login() {
  const { dispatch } = useAppContext();
  const [errorMessages, setErrorMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const buildPath = useNavigationPath();
  const posthog = usePostHog();

  // keep in mind that the "reservationId" param ist called "reservation" throughout the rest of the app
  // use reservationId only for the login process
  const reservationId = searchParams.get("reservationId");
  const redirect = searchParams.get("redirect");
  const email = decodeURIComponent(searchParams.get("email") ?? "");
  const { lang, setLang } = useContext(LanguageContext);
  const [currentUser, setCurrentUser] = useState(false);
  const [currentBookingNumber, setCurrentBookingNumber] = useState(false);
  const [currentReservationId, setCurrentReservationId] = useState();
  const { data, isDone, isFetching, error } = useGetBookingData(
    currentUser,
    currentBookingNumber
  );
  const formRef = useRef();

  const errors = {
    wrongCredentials: dict("login.error", lang),
    unexpectedError: dict("login.unexpectedError", lang),
  };
  const handleSubmit = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }

      setIsLoading(true);

      let { email, booking_number } = formRef.current;

      const isReservationId = (id) => id.includes("-");
      const reservationId = isReservationId(booking_number.value)
        ? booking_number.value
        : "";
      const bookingNumber = booking_number.value.split("-")[0];

      try {
        const checkInStatusRequest = CheckinService.getStatus(bookingNumber);
        const loginRequest = AuthService.login(email.value, bookingNumber);

        const [checkInStatus, user] = await Promise.all([
          checkInStatusRequest,
          loginRequest,
        ]);

        if (checkInStatus?.status === "checkedIn") {
          dispatch(checkInUser());
        }
        if (user && user.token) {
          posthog?.identify(email.value, {
            email: email.value,
          });
          pushToHubSpot([
            "identify",
            {
              email: email.value,
            },
          ]);
          pushToHubSpot(["setPath", window?.location?.pathname]);
          pushToHubSpot(["trackPageView"]);

          setLang(user.preferredLanguage);
          dispatch(setUser(user));
          setCurrentUser(user);
          setCurrentReservationId(reservationId);
          setCurrentBookingNumber(bookingNumber);
          setErrorMessages({});
        }
      } catch (e) {
        setErrorMessages({
          name: "wrongCredentials",
          message: errors.wrongCredentials,
        });
        setIsLoading(false); // Set isLoading to false when there's an error
      }
    },
    [setLang, dispatch, errors.wrongCredentials]
  );

  useEffect(() => {
    if (reservationId && email) {
      void handleSubmit(null);
    }
  }, [reservationId, email, handleSubmit]);

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && <Error>{errorMessages.message}</Error>;

  const stayStatus = useStayStatus({
    departureDateString: data?.["hydra:member"]?.[0]?.departure,
  });

  useEffect(() => {
    if (!!error) {
      setErrorMessages({
        name: "unexpectedError",
        message: errors.unexpectedError,
      });
      setIsLoading(false);
    }

    if (isFetching || !isDone) return;
    if (!data || !stayStatus || !data["hydra:member"]) {
      console.error("no booking found");
      return;
    }

    const hasWatchedVideoOnce = localStorage.getItem("hasWatchedVideoOnce");

    const numBookings = data["hydra:member"].length;
    localStorage.setItem("numBookings", numBookings);

    if (stayStatus.isAfterStay) {
      return navigate(
        buildPath("/after-stay", {
          reservation: currentReservationId,
        })
      );
    }

    if (stayStatus.isBeforeOrDuringStay) {
      if (!!redirect) {
        const firstReservationId = data["hydra:member"][0].identifier;

        const url = new URL(`${window.location.origin}${redirect}`);
        // remove params only relevant for authentication
        url.searchParams.delete("reservationId");
        url.searchParams.delete("email");
        // prevent chains of redirects
        url.searchParams.delete("redirect");

        url.searchParams.set("reservation", firstReservationId);

        console.info(
          "redirecting after login to: ",
          getCompletePathFromURL(url)
        );

        return navigate(getCompletePathFromURL(url));
      }

      // have user choose a reservationId first
      if (!currentReservationId) {
        return navigate(buildPath("/"));
      }

      if (hasWatchedVideoOnce) {
        // console.info("hasWatchedVideoOnce");
        return navigate(
          buildPath("/welcome", {
            reservation: currentReservationId,
          })
        );
      }

      // console.info("to loading");
      return navigate(
        buildPath("/loading", {
          reservation: currentReservationId,
        })
      );
    }

    // This should never happen
    throw new Error(
      `Invalid state when redirecting from login. ${JSON.stringify(data)}`
    );
  }, [
    reservationId,
    email,
    handleSubmit,
    isFetching,
    isDone,
    data,
    stayStatus,
    redirect,
  ]);

  const LoginForm = () => (
    <FormWrapper>
      {renderErrorMessage("wrongCredentials")}
      <form onSubmit={handleSubmit} ref={formRef}>
        <InputContainer>
          <H>{dict("login.mail", lang)}</H>
          <DataInput
            disabled={isLoading}
            type="email"
            name="email"
            required
            defaultValue={email}
          />
          {renderErrorMessage("wrongEmail")}
        </InputContainer>
        <InputContainer>
          <H>{dict("login.booking_number", lang)}</H>
          <BookingNumberField
            formRef={formRef}
            disabled={isLoading}
            name="booking_number"
            required
            defaultValue={reservationId}
          />
          {renderErrorMessage("wrongBooking")}
          {renderErrorMessage("unexpectedError")}
        </InputContainer>
        <ButtonContainer>
          <Button
            className="yellow"
            $loading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? dict("login.wait", lang) : dict("login.log", lang)}
          </Button>
        </ButtonContainer>
      </form>
    </FormWrapper>
  );

  // Return TechnicalOutage instead of login if companion is unavailable
  // return <TechnicalOutage />

  return (
    <div>
      <LoginWrapper>
        <Logo src={logo} alt="logo" />
        <Image src={house} alt="house" />
        <LoginBox>
          <LoginTitle>{dict("login.title", lang)} </LoginTitle>
          <LoginIntro>{dict("login.text", lang)}</LoginIntro>
          <LoginForm />
        </LoginBox>
      </LoginWrapper>
    </div>
  );
}

const BookingNumberField = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  /** The BookingNumber field has type password, so it can be autofilled by the browser or Password Mangers. (e.g. 1Password)
   * While the user is "using" the field we want the content to be visible, so we switch to type="text" */
  return (
    <DataInput
      type={
        isFocused ||
        !!props?.formRef?.current?.booking_number?.value?.length > 0
          ? "text"
          : "password"
      }
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...props}
    />
  );
};

export default Login;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_ENPOINT;

class BookingService {
  getReservation(id) {
    return axios.get(API_URL + id, { headers: authHeader() });
  }

  getReservations(bookingId) {
    return axios.get(
      API_URL + "/api/private/bookings/" + bookingId + "/reservations",
      {
        headers: authHeader(),
      }
    );
  }
}

export default new BookingService();

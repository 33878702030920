import { createGlobalStyle } from "styled-components";
import { p, sansSerif } from "./Typography";

export const GlobalStyles = createGlobalStyle`

  html {
    background-color: rgb(253, 249, 237);
    max-width: 500px;
    margin: 0 auto;
    padding: 0 1rem 96px; /* padding-top: 0rem; */
    font-family: ${sansSerif};
    color: #23212C;
  }

  img {
    // max-width: 100%
  }

  input {
    ${p};
  }


`;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_ENPOINT;

class UnitGroupService {
  getPropertyByPath(path) {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new UnitGroupService();

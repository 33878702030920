export const sendEvent = (params) => {
  window.dataLayer?.push(params);
};

export const pushToHubSpot = (params) => {
  // console.log("pushToHubSpot", params);
  window._hsq?.push(params);
};

export const pushToHotjar = ({ name }) => {
  // console.log("pushToHotjar", name);
  window.hj?.("event", name);
};

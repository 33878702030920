import styled, { css } from "styled-components";
import { p, h3Alt } from "./Typography";
import theme from "../../utils/theme";

const rem = (val) => `${val / 16}rem`;
export const sansSerif = `'neue-haas-unica', sans-serif`;
export const serif = `'Bradford LL', serif`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  padding-top: 16px;

  &.animate {
    animation-duration: 0.75s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-fill-mode: backwards;

    @keyframes animate-fade {
      0% {
        opacity: 0%;
        transform: scale(0.8, 0.8);
      }
      100% {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
`;

export const Logo = styled.img`
  max-width: 50vh;
  margin-bottom: 48px;
`;

export const Image = styled.img`
  max-width: 50vh;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 32px;
  background: #ffffff;
  border-radius: 26px;
`;

export const LoginTitle = styled.div`
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
  ${h3Alt}
`;

export const LoginIntro = styled.div`
  justify-content: center;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 32px;
  ${p}
`;

export const LoginText = styled.div`
  justify-content: center;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 16px;
  ${p}
`;

export const LoginShortText = styled.div`
  justify-content: center;
  text-align: center;
  letter-spacing: -0.01em;
  ${p}
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 13px 12px;
  width: 100%;

  ${p}
  &.primary {
    color: #ffffff;
    background: #23212c;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
  }

  &.yellow {
    color: #b318a4;
    background: #fcbd1c;
  }

  ${(props) =>
    props.loading &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
`;

export const DataInput = styled.input.attrs(({ type }) => ({
  type: type || "text",
}))`
  border-radius: 16px;
  border: 1px solid #23212c;
  padding: 8px 12px;
  margin-bottom: 18px;
  font-size: 18px;

  &:disabled {
    border: transparent;
    cursor: not-allowed;
    background: ${theme.colors.gray};
    color: ${theme.colors.grayDark};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.div`
  margin-top: -12px;
  color: #b90707;
  margin-bottom: 18px;
`;

export const Video = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoContent = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Slogan = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  font-size: ${rem(40)};
  color: rgb(253, 249, 237);
  font-weight: 300;
  letter-spacing: -0.03em;
  font-family: ${serif};
`;

export const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

import picture from "assets/images/illu-blumen.svg";
import logo from "assets/images/logo.svg";
import ButtonLink from "atoms/ButtonLink";
import { ExtraBox } from "components/Contents/styles/Contents.styled";
import { useAppContext, useAppState } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import dict from "utils/translations";
import {
  FormWrapper,
  Image,
  LoginBox,
  LoginIntro,
  LoginText,
  LoginTitle,
  LoginWrapper,
  Logo,
} from "./styles/Login.styled";
import { useNavigationPath } from "../hooks/useNavigationPath";

const ButtonWrapper = styled(ExtraBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

function Welcome() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  console.log("reservationId in welcome: ", reservationId);

  return (
    <LoginWrapper className="animate">
      <Logo src={logo} alt="logo" />
      <Image src={picture} alt="picture" />
      <LoginBox>
        <WelcomeBoxContent reservationId={reservationId} />
      </LoginBox>
    </LoginWrapper>
  );
}

function WelcomeBoxContent({ reservationId }) {
  const { lang } = useContext(LanguageContext);
  const { getReservation } = useAppContext();
  const { hasCheckedIn, user } = useAppState();
  const reservation = getReservation(reservationId);
  const buildPath = useNavigationPath();
  console.log("reservation after getReservation in Welcome: ", reservation);

  const pathToReservation = buildPath("/reservation", {
    reservation: reservation.identifier,
  });

  if (hasCheckedIn) {
    return (
      <>
        <LoginTitle>
          {dict("pages.welcomeTitle", lang)} {user.firstName},
        </LoginTitle>
        <LoginText>{dict("pages.welcomeIntro", lang)}</LoginText>
        <LoginIntro>{dict("pages.defaultWelcomeText", lang)}</LoginIntro>
        <FormWrapper>
          <ButtonLink
            className="greenFull"
            icon={null}
            caption={dict("pages.defaultWelcomeButton", lang)}
            to={pathToReservation}
          ></ButtonLink>
        </FormWrapper>
      </>
    );
  }

  return (
    <>
      <LoginTitle>
        {dict("pages.welcomeTitle", lang)} {user.firstName},
      </LoginTitle>
      <LoginText>{dict("pages.welcomeIntro", lang)}</LoginText>
      <LoginIntro>{dict("pages.welcomeText", lang)}</LoginIntro>
      <FormWrapper>
        <ButtonWrapper>
          <ButtonLink
            className="greenFull"
            icon={null}
            caption={dict("pages.welcomeButton", lang)}
            to={buildPath("/check-in", { reservation: reservation.identifier })}
          ></ButtonLink>
          <ButtonLink
            className="whiteFull"
            icon={null}
            caption={dict("pages.checkinLater", lang)}
            to={pathToReservation}
          />
        </ButtonWrapper>
      </FormWrapper>
    </>
  );
}

export default Welcome;

import { css } from "styled-components";

const rem = (val) => `${val / 16}rem`;

const reset = css`
  font-weight: normal;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const resetButUnderlineLink = css`
  font-weight: normal;
  text-decoration: none;
`;

export const sansSerif = `'neue-haas-unica', sans-serif`;
export const serif = `'Bradford LL', serif`;

export const h1 = css`
  ${reset};
  font-size: ${rem(40)};
  color: #23212c;
  line-height: ${rem(38)};
  font-weight: 300;
  letter-spacing: -0.03em;
  font-family: ${sansSerif};
`;

export const h1Alt = css`
  ${reset};
  font-size: ${rem(40)};
  line-height: ${rem(38)};
  font-weight: 300;
  letter-spacing: -0.02em;
  font-family: ${sansSerif};
  color: #23212c;
`;

export const h2 = css`
  ${reset};
  font-size: ${rem(32)};
  line-height: ${rem(32)};
  font-weight: 300;
  letter-spacing: -0.015em;
  font-family: ${sansSerif};
  color: #006434;
`;

export const h2Green = css`
  ${reset};
  font-size: ${rem(32)};
  line-height: ${rem(32)};
  font-weight: 300;
  letter-spacing: -0.015em;
  font-family: ${sansSerif};
  color: #006434;
`;

export const h3Green = css`
  ${reset};
  font-size: ${rem(28)};
  line-height: ${rem(28)};
  font-weight: 300;
  letter-spacing: -0.015em;
  font-family: ${sansSerif};
  color: #006434;
`;

export const h2Alt = css`
  ${reset};
  font-size: ${rem(30)};
  color: #23212c;
  line-height: ${rem(28)};
  font-family: ${serif};
  font-weight: 300;
  letter-spacing: -0.02em;
`;

export const h3 = css`
  ${reset};
  font-size: ${rem(28)};
  color: #23212c;
  line-height: ${rem(28)};
  font-weight: 300;
  letter-spacing: -0.015em;
  font-family: ${sansSerif};
`;

export const h3Alt = css`
  ${reset};
  font-size: ${rem(28)};
  color: #23212c;
  line-height: ${rem(28)};
  font-family: ${serif};
  font-weight: 300;
  letter-spacing: -0.015em;
`;

export const h4 = css`
  ${resetButUnderlineLink};
  font-size: ${rem(14)};
  color: #23212c;
  line-height: ${rem(18)};
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-family: ${sansSerif};
  font-weight: 600;
`;

export const h4Alt = css`
  ${reset};
  font-size: ${rem(24)};
  color: #23212c;
  line-height: ${rem(26)};
  font-family: ${serif};
  font-weight: 300;
  letter-spacing: -0.015em;
`;

export const h5 = css`
  ${reset};
  font-size: ${rem(12)};
  color: #23212c;
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  text-transform: uppercase;
  font-family: ${sansSerif};
`;

export const h5Green = css`
  ${reset};
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  text-transform: uppercase;
  font-family: ${sansSerif};
  color: #006434;
`;

export const h5GreenSmall = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  font-family: ${sansSerif};
  color: #006434;
`;

export const pLarge = css`
  ${reset};
  font-size: ${rem(20)};
  color: #23212c;
  line-height: ${rem(24)};
  letter-spacing: -0.01em;
  font-family: ${sansSerif};
`;

export const pLargeGreen = css`
  ${reset};
  font-size: ${rem(20)};
  color: #23212c;
  line-height: ${rem(24)};
  letter-spacing: -0.01em;
  font-family: ${sansSerif};
  color: #006434;
`;

export const pLargeAlt = css`
  ${reset};
  font-size: ${rem(20)};
  color: #23212c;
  line-height: ${rem(24)};
  font-family: ${serif};
  font-weight: 300;
  letter-spacing: -0.01em;
`;

export const p = css`
  ${reset};
  font-size: ${rem(16)};
  color: #23212c;
  line-height: ${rem(20)};
  letter-spacing: -0.01em;
  font-family: ${sansSerif};
`;

export const pSmall = css`
  ${reset};
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  letter-spacing: -0.005em;
  font-family: ${sansSerif};
  color: #23212c;
`;

export const pTiny = css`
  ${reset};
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  font-family: ${sansSerif};
`;

import axios from "axios";
import jwt_decode from "jwt-decode";
import { clearLocalStorage } from "../utils/clearLocalStorage";

const API_URL = process.env.REACT_APP_API_ENPOINT + "/api/";

class AuthService {
  login(email, booking_number) {
    // clear local storage to prevent inconsistent state
    clearLocalStorage(["hasWatchedVideoOnce"]);

    return axios
      .post(API_URL + "login", { email, booking_number })
      .then((response) => {
        let user = {
          token: null,
          firstName: null,
          lastName: null,
          preferredLanguage: "de",
        };
        if (response.data.token) {
          let decoded = jwt_decode(response.data.token);
          user.token = response.data.token;
          user.firstName = decoded.firstName;
          user.lastName = decoded.lastName;
          user.preferredLanguage = decoded.preferredLanguage
            ? decoded.preferredLanguage
            : "de";
        }
        return user;
      })
      .catch(function (error) {
        throw error;
      });
  }

  getCurrentLanguage() {
    let currentLang = "de";
    if (localStorage.getItem("user") !== null) {
      currentLang = JSON.parse(localStorage.getItem("user")).preferredLanguage;
    }
    return currentLang;
  }
}

export default new AuthService();

import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";

function Emergency() {
  const { lang } = useContext(LanguageContext);

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.emergency", lang)}
      subTitle={dict("contentItems.help.title", lang)}
      contentId="emergency"
    />
  );
}

export default Emergency;

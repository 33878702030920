import { createGlobalStyle } from "styled-components";

import BradfordLLLightWoff1 from "assets/fonts/BradfordLLSub-Light.woff";
import BradfordLLLightWoff2 from "assets/fonts/BradfordLLSub-Light.woff2";

export default createGlobalStyle`
@font-face {
    font-family: 'Bradford LL';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url(${BradfordLLLightWoff2}) format('woff2'),
      url(${BradfordLLLightWoff1}) format('woff');
  },
`;

import { p, pLarge, sansSerif } from "pages/styles/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Button = styled(Link)`
  ${pLarge}
  box-sizing: border-box;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  overflow-wrap: break-word;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  width: auto;
  max-height: auto;
  font-family: ${sansSerif};
  background: rgba(35, 33, 44, 0.1);

  /* grayscale/black */

  border-radius: 20px;

  /* Inside auto layout */

  flex: none;

  &.primary {
    color: #ffffff;
    background: #23212c;
    justify-content: center;
    gap: 12px;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
    justify-content: center;
    gap: 12px;
  }

  &.greenFull {
    color: #592db5;
    background: #bdda0e;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }

  &.whiteFull {
    color: #23212c;
    background: #ffffff;
    justify-content: center;
    border: 1px solid #23212c;
    border-radius: 20px;
    gap: 12px;
    width: 100%;
  }

  &.light {
    color: #23212c;
    background: #bdda0e;
  }

  &.orange {
    color: #b318a4;
    background: #fcbd1c;
    justify-content: center;
    gap: 12px;
  }
`;

export const ButtonAsDiv = styled.div`
  ${pLarge};
  box-sizing: border-box;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  overflow-wrap: break-word;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  width: auto;
  max-height: auto;
  font-family: ${sansSerif};
  background: rgba(35, 33, 44, 0.1);

  /* grayscale/black */

  border-radius: 20px;

  /* Inside auto layout */

  flex: none;

  &.primary {
    color: #ffffff;
    background: #23212c;
    justify-content: center;
    gap: 12px;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
    justify-content: center;
    gap: 12px;
  }

  &.light {
    color: #23212c;
    background: #bdda0e;
  }

  &.orange {
    color: #b318a4;
    background: #fcbd1c;
    justify-content: center;
    gap: 12px;
  }
`;

export const ExtraButton = styled.a`
  ${pLarge}
  box-sizing: border-box;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  overflow-wrap: break-word;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  width: auto;
  max-height: auto;
  font-family: ${sansSerif};

  /* grayscale/black */

  background: rgba(35, 33, 44, 0.1);
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
`;

export const ButtonTo = styled(Link)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 13px 12px;
  width: 100%;
  ${p}

  &.primary {
    color: #ffffff;
    background: #23212c;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
  }

  &.light {
    color: #23212c;
    background: #bdda0e;
  }

  &.orange {
    color: #b318a4;
    background: #fcbd1c;
    justify-content: center;
    gap: 12px;
  }
`;

export const ButtonToSmall = styled(Link)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.className === "grey" ? "default" : "pointer")};
  border-radius: 12px;
  border: none;
  padding: 4.5px 10px 7.5px 10px;
  ${p}

  &.primary {
    color: #ffffff;
    background: #23212c;
    width: 100%;
  }

  &.grey {
    color: #23212c;
    background: rgba(35, 33, 44, 0.1);
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
    width: 100%;
  }

  &.light {
    color: #23212c;
    background: #bdda0e;
  }

  &.orange {
    color: #b318a4;
    background: #fcbd1c;
    justify-content: center;
    width: 100%;
  }
`;

export const LinkButtonTo = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 13px 12px;
  width: 100%;
  ${p}

  &.primary {
    color: #ffffff;
    background: #23212c;
  }

  &.green {
    color: #592db5;
    background: #bdda0e;
  }
`;

export const contents = [
  {
    id: "beforeTrip",
    icon: "/images/explore.svg",
    contents: [],
  },
  {
    id: "myStay",
    icon: "/images/cabin.svg",
    contents: [],
    showAlways: true,
  },
  {
    id: "cabinRules",
    icon: "/images/checklist_rtl.svg",
    contents: [],
  },
  {
    id: "food",
    icon: "/images/add_task.svg",
    showAlways: true,
    contents: [],
  },
  {
    id: "activities",
    icon: "/images/local_activity.svg",
    showAlways: true,
    contents: [],
  },
  {
    id: "help",
    icon: "/images/health_and_safety.svg",
    contents: [],
  },
  {
    id: "basicFacts",
    icon: "/images/explore.svg",
    contents: [],
  },
  {
    id: "checkout",
    icon: "/images/logout.svg",
    contents: [],
  },
];

export const extraContents = [
  {
    id: "F&B",
    icon: "/images/restaurant.svg",
    contents: [],
  },
  {
    id: "Experiences",
    icon: "/images/local_activity.svg",
    contents: [],
  },
];

export const ReservationsListData = [
  {
    id: "bookedCabins",
    title: "Gebuchte Cabins",
    expanded: true,
  },
];

import styled, { css } from "styled-components";
import { h3, h3Alt, h5, p, pSmall } from "./Typography";
import down from "../../assets/images/keyboard_arrow_down.svg";
import theme from "../../utils/theme";
import { Text } from "../../components/Contents/styles/Contents.styled";

export const CheckInWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  &.animate {
    animation-duration: 0.75s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-fill-mode: backwards;

    @keyframes animate-fade {
      0% {
        opacity: 0%;
        transform: scale(0.8, 0.8);
      }
      100% {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
`;

export const FormWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 32px;
`;

export const FormSection = styled.div`
  margin-bottom: 40px;
`;

export const FormGroup = styled.div`
  margin-bottom: 10px;
  // padding: 20px 0px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 26px;
  width: 100%;
`;
export const Heading = styled.div`
  ${h3};
  margin-bottom: 12px;
  align-self: flex-start;
`;

export const SubHeading = styled.div`
  ${p}
  font-size: 18px;
  margin-bottom: 32px;
`;

export const FormHeadingContainer = styled.div`
  margin-bottom: 16px;
`;

export const FormSubHeading = styled.div`
  ${h3Alt};
  margin-bottom: 4px;
`;

export const FormExplainer = styled(Text)`
  color: ${theme.colors.grayDark};
`;

export const LabelWrapper = styled.div`
  margin-bottom: 6px;
`;
export const Label = styled.label`
  ${h5}
  display: inline-block;
  max-width: 100%;
`;

const disabledButtonStyles = css`
  background-color: ${theme.colors.gray};
  color: ${theme.colors.grayDark};
  cursor: not-allowed;
`;

const greenButtonStyles = css`
  color: #592db5;
  background: #bdda0e;
`;

const primaryButtonStyles = css`
  color: #ffffff;
  background: #23212c;
`;

const yellowButtonStyles = css`
  color: #b318a4;
  background: #fcbd1c;
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 13px 12px;
  width: 100%;
  background-color: #fcbd1c;
  border-color: #fcbd1c;
  ${p}
  ${({ variant, disabled }) => {
    if (disabled) {
      return disabledButtonStyles;
    }
    switch (variant) {
      case "primary":
        return primaryButtonStyles;
      case "green":
        return greenButtonStyles;
      case "yellow":
        return yellowButtonStyles;
      default:
        return "";
    }
  }}
`;

export const ButtonAdd = styled.button`
  ${p}
  color: #FFFFFF;
  background: #23212c;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 12px;
  border: none;
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ButtonRemove = styled.button`
  ${p}
  color: #FFFFFF;
  background: #b90707;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 12px;
  border: none;
  margin-bottom: 32px;
  margin-top: 8px;
`;
export const ButtonClear = styled.button`
  color: #fff;
  background: #cb0000;
  border-color: #cb0000;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 6px;
  border: none;
`;

export const Error = styled.div`
  margin-top: -12px;
  color: #b90707;
  margin-bottom: 18px;
`;
export const SignatureContainer = styled.div`
  border: 1px solid #23212c;
  border-radius: 16px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const InputField = styled(Text).attrs({ as: "input" })`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  margin-bottom: 16px;
  background: rgb(253, 249, 237);

  width: 100%;
  height: 48px;

  /* grayscale/black */

  border: 1px solid #23212c;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  :disabled {
    border: transparent;
    cursor: not-allowed;
    background: ${theme.colors.gray};
    color: ${theme.colors.grayDark};
  }

  ::placeholder {
    color: ${theme.colors.gray};
  }
`;

export const FlexInputField = styled(InputField)`
  flex: 1;
  min-width: 0;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 16)}px;
`;

export const Select = styled.select`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 12px;
  gap: 10px;

  width: 100%;
  height: 48px;
  background: rgb(253, 249, 237);

  /* grayscale/black */

  border: 1px solid #23212c;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* hide default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url(${down});
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
`;

export const LastSelect = styled.select`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 12px;
  gap: 10px;

  width: 100%;
  height: 48px;
  background: rgb(253, 249, 237);

  /* grayscale/black */

  border: 1px solid #23212c;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* hide default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url(${down});
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
`;

export const SubmitDisclaimer = styled.div`
  ${pSmall};
  display: flex;
  justify-content: center;
  margin-top: 8px;
  color: ${theme.colors.error};
`;

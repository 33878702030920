import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

const tagManagerArgs = {
  gtmId: "G-DTETZ93SJY",
};
TagManager.initialize(tagManagerArgs);

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

Sentry.init({
  dsn: "https://0d36b60f29f54437a6c54f93279ed492@o1420633.ingest.sentry.io/4504450808610816",
  integrations: [new BrowserTracing()],
  enabled: process.env.NODE_ENV !== "development",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate of how many non-error sessions should be recorded
  // we set it to 0 because we're mainly interested in replays of errors
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 6,
  integrations: [
    new Sentry.Replay({
      maskAllInputs: true,
    }),
  ],
});

hotjar.initialize(3310756, 6);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={postHogOptions}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

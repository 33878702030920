import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";

function HelpingHandGuide() {
  const { lang } = useContext(LanguageContext);

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.helpingHandGuide", lang)}
      subTitle={dict("contentItems.helpingHandGuide.title", lang)}
      contentId="helping_hand_guide"
    />
  );
}

export default HelpingHandGuide;

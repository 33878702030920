import arrowRight from "assets/images/arrow-right.svg";
import car from "assets/images/car2.svg";
import dog from "assets/images/dog.svg";
import { ButtonToSmall } from "./styles/ButtonLink.styled";

function ButtonLinkSmall({
  className = "",
  caption,
  to = "/",
  onClick = false,
  icon = arrowRight,
}) {
  //TODO: Refactor to not make decisions based on classNames.
  const isGreen = className.includes("green");

  const buttonStyles = isGreen ? { justifyContent: "center" } : {};

  const iconStyles =
    !!icon && (icon === dog || car)
      ? {
          marginTop: "1%",
          width: "24px",
          height: "24px",
          objectFit: "scale-down",
        }
      : { marginTop: "1%" };

  const props = {
    className,
    to,
  };

  if (onClick) {
    props.onClick = onClick;
  }
  if (to === false) {
    // console.log("del to");
    delete props.to;
  }
  // console.log("icon", icon);

  return (
    <ButtonToSmall {...props} style={buttonStyles}>
      {caption}
      {!!icon && <img src={icon} alt="icon" style={iconStyles} />}
    </ButtonToSmall>
  );
}

export default ButtonLinkSmall;

import * as Sentry from "@sentry/browser";
import axios from "axios";
import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import authHeader from "./auth-header";
dayjs.extend(isBetween);

const API_URL = process.env.REACT_APP_API_ENPOINT + "/api/";
const RAUS_API_URL = process.env.REACT_APP_RAUS_WEB_ENDPOINT;
const METADATA_URL = process.env.REACT_APP_METADATA_API_ENPOINT + "locations/";
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

class ServiceService {
  enrichServiceFromMetadata(services, servicesAdditions) {
    // console.log("services", services);
    // console.log("servicesAdditions", servicesAdditions);
    // enrich the servicesAdditions from metadata with bookable services from apaleo
    let enrichedServices = servicesAdditions
      .map((enrichedService) => {
        // console.log("enrichedService.code " + enrichedService.code);
        let service = services.find(
          (item) => item.code === enrichedService.code
        );

        // no service found? skip this one because its not sellable on apaleo
        if (!service) {
          return false;
        }

        // console.log("service before", service);
        // console.log(
        //   "enrichedService.blocked_dates",
        //   enrichedService.blocked_dates
        // );
        // if (service.code === "CP_BF_ARC") {
        //   console.log("CP_BF_ARC");
        const datesCleanup = createExtraDates(
          service.dates,
          enrichedService.blocked_dates
        );
        // console.log("datesCleanup", datesCleanup);
        service.dates = datesCleanup.dates;
        service.hasAppliedBockedDays = datesCleanup.hasAppliedBockedDays;
        // }

        Object.assign(service, {
          type: enrichedService.type,
          description: enrichedService.description,
          name: enrichedService.name,
          availabilityQuantity: enrichedService.availibility_quantity,
          availabilityMode: enrichedService.availability_mode,
          quantityInfo: enrichedService.quantity_info,
          pricingInfo: enrichedService.pricing_info,
          pricingUnit: enrichedService.pricing_unit,
          blockedDates: enrichedService.blocked_dates,
          bookableUntilHoursBeforeArrival:
            enrichedService.bookable_until_hours_before_arrival,
          activityInfo: enrichedService.activity_info,
          usageCode: enrichedService.usage_code,
          partner: enrichedService.partner,
        });
        return service;
      })
      .filter(function (service) {
        // we remove the falsy filters, those stem from the return false when !serviceAdditions are not found
        return !!service;
      });
    return enrichedServices;
  }

  enrichServiceWithActivityStatus(services, activityStatusFromHubspotReponse) {
    // console.log('enrichServiceWithActivityStatus', activityStatusFromHubspotReponse)
    // enrich the bookable services with info from hubspot about activity requests
    let enrichedServices = services
      .map((service) => {
        let activityStatus =
          activityStatusFromHubspotReponse[service.identifier];
        // console.log('activityStatus', activityStatus)
        Object.assign(service, {
          activityStatus: activityStatus,
        });
        // console.log('service', service)
        return service;
      })
      .filter(function (service) {
        // we remove the falsy filters, those stem from the return false when !activityStatus are not found
        return !!service;
      });
    return enrichedServices;
  }

  getActivityRequestStatus(services, servicesAdditions) {
    // status mapping from `Guest Experience` Ticket Pipeline in HubSpot
    // https://app-eu1.hubspot.com/pipelines-settings/25939496/object/0-5/25144271
    // 79645902 = new
    // 78967996 = open
    // 78967999 = closed
    let enrichedServices = services
      .map((service) => {
        let serviceAdditions = servicesAdditions[service.code];
        // console.log('serviceAdditions for ' + service.code, serviceAdditions)

        // no additions found? skip this one because we do not have all infos
        if (!serviceAdditions) {
          return false;
        }
        Object.assign(service, {
          type: serviceAdditions.type,
          availabilityMode: serviceAdditions.availability_mode,
          quantityInfo: serviceAdditions.quantity_info,
          pricingInfo: serviceAdditions.pricing_info,
          pricingUnit: serviceAdditions.pricing_unit,
          blockedDates: serviceAdditions.blocked_dates,
          bookableUntilHoursBeforeArrival:
            serviceAdditions.bookable_until_hours_before_arrival,
        });
        // return {...service, serviceAdditions}
        return service;
      })
      .filter(function (service) {
        // we remove the falsy filters, those stem from the return false when !serviceAdditions are not found
        return !!service;
      });
    return enrichedServices;
  }

  async getBookedServicesForReservation(reservationId, servicesAdditions) {
    return axios
      .get(API_URL + "private/reservations/" + reservationId + "/services", {
        headers: authHeader(),
        params: {
          availability: "booked",
        },
      })
      .then((response) => {
        let bookedServices = this.enrichServiceFromMetadata(
          response.data["hydra:member"],
          servicesAdditions
        );
        return bookedServices;
      })
      .catch(function (error) {
        Sentry.captureException(error);
        // no services for reservation, so... not an actual error
        // console.log('error:', error)
        if (error.response?.status === 401) {
          return 401;
        }
        return [];
        // } else {
        //   // console.log('error2', error)
        //   return []
        // }
      });
  }

  async getBookableServicesForReservation(
    reservationId,
    servicesAdditions,
    activityStatusFromHubspotReponse
  ) {
    return axios
      .get(API_URL + "private/reservations/" + reservationId + "/services", {
        headers: authHeader(),
        params: {
          availability: "available_for_booking",
        },
      })

      .then(async (response) => {
        // enrich the bookable services with activity request status
        let bookableServices = this.enrichServiceWithActivityStatus(
          response.data["hydra:member"],
          activityStatusFromHubspotReponse
        );

        // enrich the bookable services with info from metadata
        bookableServices = await this.enrichServiceFromMetadata(
          response.data["hydra:member"],
          servicesAdditions
        );

        return bookableServices;
      })
      .catch(function (error) {
        // no services for reservation, so... not an actual error
        Sentry.captureException(error);
        // if (error.response?.status === 404) {
        return [];
        // } else {
        //   // console.log('error2', error)
        //   return []
        // }
      });
  }

  async getIncludedServicesOfBookedRatePlan(reservationId, servicesAdditions) {
    return axios
      .get(
        RAUS_API_URL +
          "/api/companion/rate-plan-for-reservation?reservationId=" +
          reservationId
      )
      .then((response) => {
        return response.data.rateplan.includedServices || [];
      })
      .catch(function (error) {
        Sentry.captureException(error);
        return [];
      });
  }

  // await ServiceService.bookService(reservationId, serviceId, [
  //     { "serviceDate": "2023-04-14", "amount": { "currency": "EUR", "amount": 12 }, "count": 1 },
  //     { "serviceDate": "2023-04-15", "amount": { "currency": "EUR", "amount": 12 }, "count": 1 }
  // ]);
  async bookService(reservationId, servicesId, count, date = null) {
    let payload = {
      serviceId: servicesId,
      count: count,
    };
    if (date?.length) {
      payload = {
        ...payload,
        dates: date.map((serviceDate) => ({
          serviceDate,
        })),
      };
    }

    return axios
      .post(
        "https://hook.eu1.make.com/f2eccxp8va2mgnu4fadd33iu6qh2a4ov/?reservationId=" +
          reservationId,
        payload
      )
      .then((response) => {
        console.log("response from booking service: ", response);
        return true;
      })
      .catch(function (error) {
        Sentry.captureException(error);
        return false;
      });
  }

  async getServicesAdditions(spotCode, lang) {
    // console.log('getServicesAdditions lang', lang)
    // always get EN here as we only care about the restrictions
    try {
      const response = await axios.get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          // this filter is not working and its a da before christmas. filtering a little further down instead
          "filters[spot_code][$eq]": spotCode,
          // "filters[services][channelcode_companion][$eq]": "true",
          // "filters[services][type][$ne]": "Experience-on-request",
          locale: "en",
          fields: "spot_code",
          "populate[services][populate][0]": "localizations",
          "populate[services][populate][1]": "blocked_dates",
          "populate[services][populate][2]": "overview_image.image",
          "populate[services][populate][3]": "activity_info",
          "populate[services][populate][4]": "partner",
          "populate[services][populate][5]": "usage_code",
          "populate[services][populate][6]": "localizations.activity_info",
        },
      });

      const servicesFromResponse =
        response.data?.data[0]?.attributes?.services?.data || [];

      // this filter should not need to be here but i am lazy today
      const services = servicesFromResponse
        .filter((service) => {
          // only services that are enabled for companion OR that are enabled for IBE and are LATE_CHE (late checkin for sunday 2 night bookings)
          return (
            service.attributes.channelcode_companion === true ||
            (service.attributes.channelcode_ibe === true &&
              service.attributes.service_code === "LATE_CHE")
          );
        })
        .map((service) => {
          let {
            blocked_dates,
            activity_info,
            usage_code,
            localizations,
            overview_image,
            ...enContent
          } = service.attributes;
          let localizedContent = enContent;

          if (lang !== "en") {
            localizedContent = service.attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === lang;
              }
            ).attributes;

            activity_info =
              localizedContent.activity_info &&
              localizedContent.activity_info !== null
                ? localizedContent.activity_info
                : enContent.activity_info;
          }

          return {
            code: service.attributes.service_code,
            blocked_dates: blocked_dates,
            activity_info: activity_info,
            usage_code: usage_code,
            partner: service.attributes.partner?.data
              ? service.attributes.partner.data.attributes
              : null,
            overview_image: overview_image,
            ...localizedContent,
          };
        });

      // console.log("services", services);

      return services;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }
}

// TODO-LUCCA: Find a solution how we can still transport the prices while still being able to block extras
const createExtraDates = (dates, blockedDates) => {
  if (!blockedDates || blockedDates.length === 0) {
    return {
      dates: dates,
      hasAppliedBockedDays: false,
    };
  }

  const datesAfterRemovingBlockedDates = removeBlockedDatesFromDates(
    dates,
    blockedDates
  );

  return {
    dates: datesAfterRemovingBlockedDates,
    hasAppliedBockedDays: datesAfterRemovingBlockedDates.length < dates.length,
  };
};

// https://chat.openai.com/share/b799929b-62b7-4d10-9fea-1a6d81a2a398
const removeBlockedDatesFromDates = (dates, blockedDates) => {
  // console.log("removeBlockedDatesFromDates dates", dates);
  // console.log("removeBlockedDatesFromDates blockedDates", blockedDates);
  return dates.filter((date) => {
    // console.log("  dates.filter date", date.serviceDate);
    const isInBlockedDate = blockedDates.some((blockedDate) => {
      // console.log(
      //   "  -> dayjs(date.serviceDate)",
      //   dayjs(date.serviceDate).format("YYYY-MM-DD")
      // );
      // console.log(
      //   "  -> dayjs(blockedDate.from)",
      //   dayjs(blockedDate.from).format("YYYY-MM-DD")
      // );
      // console.log(
      //   "  -> dayjs(blockedDate.to)",
      //   dayjs(blockedDate.to).format("YYYY-MM-DD")
      // );
      const isIn = dayjs(date.serviceDate).isBetween(
        dayjs(blockedDate.from),
        dayjs(blockedDate.to),
        "day",
        "[]" // including start and end
      );
      // console.log("  dates.filter isIn", isIn);
      return isIn;
    });
    return !isInBlockedDate;
  });
};

export default new ServiceService();

import styled from "styled-components";
import { h4Alt, p, sansSerif, serif } from "pages/styles/Typography";

export const Tile = styled.div`
  padding: ${(props) => (props.hasHRegularContent ? "0" : "24px 0 0")};
`;

export const Item = styled.div`
  border-bottom: 1px solid rgba(35, 33, 44, 0.2);
`;

export const TileWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const Image = styled.div`
  margin-bottom: 0;
`;

export const Title = styled.div`
  ${h4Alt};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 0 24px;
  gap: 12px;
  font-family: ${serif};
`;

export const TitleL = styled.div`
  ${h4Alt};
  display: flex;
  font-size: 1.75rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 0 24px;
  gap: 12px;
  font-family: ${serif};
`;

export const BasicTitle = styled.div`
  ${h4Alt};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 24px;
  gap: 12px;
  font-family: ${serif};
`;

export const ContentWrapper = styled.div`
  ${p};
  max-height: ${(props) => (props.open ? "10000000px" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  margin-bottom: ${(props) => (props.open ? "16px" : "0")};
  overflow: hidden;
  font-family: ${sansSerif};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.5s ease-in-out;
  letter-spacing: -0.01em;
`;

export const Icon = styled.div`
  img {
    max-width: 32px !important;
    max-height: 32px !important;
    padding: 0 0.5rem;
  }
`;

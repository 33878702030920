import { contents } from "data/ContentOutline";
import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";

function RegionalTips() {
  const { lang } = useContext(LanguageContext);

  return (
    <PageLayout
      isHeaderSubPageVisible={contents[0].id === "beforeTrip"}
      title={dict("pages.tips", lang)}
      subTitle={dict("contentItems.beforeTrip.title", lang)}
      contentId="region_tips"
    />
  );
}

export default RegionalTips;

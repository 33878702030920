import { useAppContext } from "provider/AppProvider";
import ReservationsItem from "./Contents/ReservationsItem";

function ReservationsList() {
  const { state } = useAppContext();
  const reservations = state.booking?.["hydra:member"];

  return (
    <div>
      {reservations.map((reservation) => (
        <ReservationsItem
          key={reservation.identifier}
          reservation={reservation}
        />
      ))}
    </div>
  );
}

export default ReservationsList;

import { useAppContext } from "provider/AppProvider";
import getCompanionContent from "utils/content";
import { ContentBox, InfoBox, Text } from "./styles/Contents.styled";

function Checkout({ contents }) {
  const { state } = useAppContext();
  const contentItem = getCompanionContent(
    "checkout",
    state.locationContent,
    state.unitGroupContent,
    state.cabinContent
  );

  return (
    <ContentBox>
      <InfoBox>
        <Text
          className="subpageContent"
          dangerouslySetInnerHTML={{ __html: contentItem.content }}
        />
      </InfoBox>
    </ContentBox>
  );
}

export default Checkout;

import { contents } from "data/ContentOutline";
import PageLayoutWithWifi from "layouts/PageLayoutWithWifi";
import { useAppContext } from "provider/AppProvider";
import { LanguageContext } from "provider/LanguageProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import dict from "utils/translations";

function Overview() {
  const { lang } = useContext(LanguageContext);
  const { getCabin, getReservation } = useAppContext();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const reservation = getReservation(reservationId);
  const cabin = getCabin(reservation);

  return (
    <PageLayoutWithWifi
      isHeaderSubPageVisible={contents[1].id === "myStay"}
      wifiTitle={dict("contents.wifi", lang)}
      wifiName={cabin.wifi_ssid}
      wifiPw={cabin.wifi_password}
      wifiText={dict("contents.wifiText", lang)}
      title={dict("pages.overview", lang)}
      subTitle={dict("contentItems.myStay.title", lang)}
      contentId="overview_where_to_find_what"
    />
  );
}

export default Overview;

import car from "assets/images/car2.svg";
import dog from "assets/images/dog.svg";
import klimLogo from "assets/images/klim-logo.svg";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import ButtonLink from "../../atoms/ButtonLink";
import ButtonLinkExtra from "../../atoms/ButtonLinkExra";
import { LanguageContext } from "../../provider/LanguageProvider";
import dict from "../../utils/translations";
import { ContentBox, InfoBox } from "../Contents/styles/Contents.styled";

function BeforeTrip({
  contents,
  unit,
  cabin,
  property,
  daysDiff,
  reservation,
}) {
  const { lang } = useContext(LanguageContext);
  const location = useLocation();

  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const petServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "PETS" || service.code === "AD_DOG_CL7"
    ) ?? false;
  const petServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "PETS" || service.code === "AD_DOG_CL7"
    ) ?? false;
  const petService = petServiceBookable || petServiceBooked;

  const mobilityServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "MOBILITY" || service.code === "AD_SN_200"
    ) ?? false;
  const mobilityServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "MOBILITY" || service.code === "AD_SN_200"
    ) ?? false;
  const mobilityService = mobilityServiceBookable || mobilityServiceBooked;

  const shuttleServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "AD_SS_C56"
    ) ?? false;
  const shuttleServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "AD_SS_C56"
    ) ?? false;
  const shuttleService = shuttleServiceBookable || shuttleServiceBooked;

  const klimServiceBookable =
    reservation?.bookableServices?.find((service) => service.code === "KLIM") ??
    false;
  const klimServiceBooked =
    reservation?.bookedServices?.find((service) => service.code === "KLIM") ??
    false;
  const klimService = klimServiceBookable || klimServiceBooked;

  return (
    <ContentBox>
      {/* {Object.keys(contents).map((key, index) => {
                return (
                    <InfoBox >
                        <ButtonLink caption={contents[key].title} to={'/' + reservationId + '/' + key} />
                    </InfoBox>
                );
            })} */}
      {daysDiff <= 1000 ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.connection", lang)}
            to={"/directions?reservation=" + reservationId}
          />
        </InfoBox>
      ) : null}
      <InfoBox>
        <ButtonLink
          caption={dict("pages.packlist", lang)}
          to={"/packliste?reservation=" + reservationId}
        />
      </InfoBox>
      {daysDiff <= 1000 ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.tips", lang)}
            to={"/tips?reservation=" + reservationId}
          />
        </InfoBox>
      ) : null}

      {petService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.pets", lang)}
            to={
              petServiceBooked
                ? "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  petService.identifier +
                  "&type=booked&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
                : "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  petService.identifier +
                  "&type=bookable&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
            }
            icon={dog}
          />
        </InfoBox>
      ) : null}

      {shuttleService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.shuttle", lang)}
            to={
              shuttleServiceBooked
                ? "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  shuttleService.identifier +
                  "&type=booked&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
                : "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  shuttleService.identifier +
                  "&type=bookable&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
            }
            icon={car}
          />
        </InfoBox>
      ) : null}

      {mobilityService ? (
        <InfoBox>
          {mobilityServiceBooked ? (
            <ButtonLinkExtra
              caption={dict("contents.mobilityStart", lang)}
              href="https://www.share-now.com/de/de/promo/PSM-RAUS/"
              icon={car}
            />
          ) : (
            <ButtonLink
              caption={dict("pages.mobility", lang)}
              to={
                "/extra?reservation=" +
                reservationId +
                "&serviceId=" +
                mobilityService.identifier +
                "&type=bookable&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
              }
              icon={car}
            />
          )}
        </InfoBox>
      ) : null}

      {klimService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.klim", lang)}
            to={
              klimServiceBooked
                ? "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  klimService.identifier +
                  "&type=booked&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
                : "/extra?reservation=" +
                  reservationId +
                  "&serviceId=" +
                  klimService.identifier +
                  "&type=bookable&extraContentItem=offers&extraTypes=Mobility%2CAdditional%2CLate-Checkout"
            }
            icon={klimLogo}
          />
        </InfoBox>
      ) : null}
    </ContentBox>
  );
}

export default BeforeTrip;

import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Slogan, Video, VideoContent } from "./styles/Login.styled";
import { useNavigationPath } from "../hooks/useNavigationPath";

function Loading() {
  const [redirect, setRedirect] = useState(null);
  const { state, getCabin, getReservation } = useAppContext();
  const { booking } = state;
  const videoRef = useRef(null);
  const location = useLocation();
  const buildPath = useNavigationPath();
  let params = queryString.parse(location.search);
  const reservationIdQueryParam =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const firstReservationInBooking = booking?.["hydra:member"]?.[0]?.identifier;

  console.log("reservationId in loading: ", reservationIdQueryParam);

  const reservation = getReservation(reservationIdQueryParam);
  const cabin = getCabin(reservation);

  useEffect(() => {
    videoRef.current = document.querySelector("video");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.setItem("hasWatchedVideoOnce", "true");
      setRedirect(
        buildPath(`/welcome`, {
          reservation: firstReservationInBooking,
        })
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, [firstReservationInBooking]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    console.log(video);

    const handleEnded = () => {
      setRedirect(
        buildPath(`/welcome`, {
          reservation: firstReservationInBooking,
        })
      );
    };
    const handlePlay = () => {
      video.parentElement.classList.remove("before-start");
    };
    const handleTimeupdate = () => {
      if (video.currentTime >= 5) {
        video.pause();
        setRedirect(
          buildPath(`/welcome`, {
            reservation: firstReservationInBooking,
          })
        );
      } else if (video.currentTime >= video.duration - 1) {
        video.parentElement.classList.add("before-end");
      }
    };

    video.addEventListener("ended", handleEnded);
    video.addEventListener("play", handlePlay);
    video.addEventListener("timeupdate", handleTimeupdate);

    return () => {
      video.removeEventListener("ended", handleEnded);
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("timeupdate", handleTimeupdate);
    };
  }, [booking, videoRef]);

  if (redirect) return <Navigate to={redirect} />;

  const videoSrc =
    cabin.name === "Schlossgarten"
      ? "/videos/c5_video_1.mp4"
      : "/videos/general_cabin_video.mp4";

  return (
    <Video className="before-start">
      <VideoContent
        ref={videoRef}
        autoPlay
        muted
        playsInline
        preload="auto"
        src={videoSrc}
      />
      <Slogan>#timewellspent</Slogan>
    </Video>
  );
}

export default Loading;

import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../provider/LanguageProvider";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  MarginP,
  NoMarginP,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  TopMarginP,
} from "./Contents/styles/Contents.styled";

function FireWarning() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const { getReservation, getCabin, state } = useAppContext();
  const { getProperty, getLocation } = useAppContext();
  const { lang } = useContext(LanguageContext);
  const reservation = getReservation(reservationId);
  const cabin = getCabin(reservation);

  const property = getProperty(reservation);
  let spotCode = reservation.property.replace("/api/properties/", "");

  function isServiceBooked(bookedServices, serviceCode) {
    return (
      Array.isArray(bookedServices) &&
      bookedServices.some((service) =>
        [serviceCode, "SAUNA"].includes(service.code)
      )
    );
  }
  function isServiceFreeInLocation({ serviceCode, spotCode }) {
    const freeServicesByLocationsMap = {
      SPOT4: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT13: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT14: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
      SPOT29: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    };
    if (!freeServicesByLocationsMap?.[spotCode]) return false;
    return freeServicesByLocationsMap[spotCode].includes(serviceCode);
  }

  function shouldShowSauna({ bookedServices, spotCode }) {
    const saunaServiceCode = "AD_SAU_DS";
    if (isServiceFreeInLocation({ serviceCode: saunaServiceCode, spotCode }))
      return true;
    return isServiceBooked(bookedServices, saunaServiceCode);
  }

  function shouldShowHotTub({ bookedServices, spotCode }) {
    const hotTubServiceCode = "AD_HOT_DS";
    if (isServiceFreeInLocation({ serviceCode: hotTubServiceCode, spotCode }))
      return true;
    return isServiceBooked(bookedServices, hotTubServiceCode);
  }

  let hasSauna = shouldShowSauna({
    bookedServices: reservation.bookedServices,
    spotCode: spotCode,
  });
  let hasHotTub = shouldShowHotTub({
    bookedServices: reservation.bookedServices,
    spotCode: spotCode,
  });

  return (
    <ThemedCard theme="black">
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding>
            <div>
              <ThemedCardTitle theme="black">
                {dict("contents.fireTitle", lang)}
              </ThemedCardTitle>
              <MarginP
                dangerouslySetInnerHTML={{
                  __html: `${dict("contents.fireText", lang)} ${
                    cabin.wbs_value
                  }.`,
                }}
              />
              <NoMarginP
                dangerouslySetInnerHTML={{
                  __html: `${dict("contents.fireSubtext", lang)}`,
                }}
              />
              {cabin.wbs_value >= 4 && (hasSauna || hasHotTub) ? (
                <TopMarginP
                  dangerouslySetInnerHTML={{
                    __html: `${dict("contents.fireSubtextSauna", lang)}`,
                  }}
                />
              ) : null}
            </div>
          </AlignIcon>
        </ThemedCardInfo>
      </CardText>
    </ThemedCard>
  );
}

export default FireWarning;

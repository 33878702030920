import PageLayout from "layouts/PageLayout";
import { LanguageContext } from "provider/LanguageProvider";
import { useContext } from "react";
import dict from "utils/translations";

function UrgentFaqs() {
  const { lang } = useContext(LanguageContext);

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.urgentFaqs", lang)}
      subTitle={dict("contentItems.urgentFaqs.title", lang)}
      contentId="urgent_faqs"
    />
  );
}

export default UrgentFaqs;
